import {
    Account,
    AccountCreation,
    AccountMail,
    AccountPage,
    AccountsApi,
    AuthApi,
    AuthResponse,
    Document,
    Role,
    Validate,
} from '@/openapi/api'
import {resetStore} from '@/store/actions/globalActions'
import modules from '@/store/modules'
import {DUPLICATE_MESSAGE, MANAGE_CAMPAIGN_NOT_STARTED, MANAGE_CAMPAIGN_RUNNING, Right} from '@/store/Right'
import {AccountPatchRequest} from '@/store/types'
import globalAxios, {AxiosResponse} from 'axios'
import Cookies from 'js-cookie'
import {flatMap} from 'lodash'
import Vue from 'vue'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const authApi: AuthApi = new AuthApi(undefined, process.env.VUE_APP_API_URL, undefined)
const accountsApi: AccountsApi = new AccountsApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class AccountsModule extends VuexModule {

    public readonly COOKIE_TOKEN = 'token'

    public username: string = ''
    public token: string | undefined = undefined
    private roles: Array<Role> = []
    private accounts: { [key: string]: Account } = {}
    private requestInterceptor: number | null = null
    private responseInterceptor: number | null = null
    private currentTokenAccount: Account | undefined = undefined
    private currentTokenError: number = 200


    /**
     * The connected user.
     */
    private me: Account | undefined = undefined

    get allRoles(): Array<Role> {
        return Object.values(this.roles)
    }

    get allAccounts(): Array<Account> {
        return Object.values(this.accounts)
    }

    public get meAccount() {
        return this.me
    }

    public get meName() {
        return this.me ? `${this.me.firstName || ''} ${this.me.lastName || ''}` : ''
    }

    public getAccountById(id: string): Account {
        const account: Account | undefined = this.allAccounts.find((r) => r.id === id)

        if (account === undefined) {
            throw new Error(`The store doesn't contain account with id=${id}.`)
        }
        return account
    }

    public getRoleById(id: string): Role | undefined {
        return this.roles.find((r) => r.id === id)
    }

    /**
     * The right list of the connected user on a given document.
     */
    public getMeRights(document: Document): Array<Right> {
        if (!this.me) {
            return []
        }
        return flatMap(this.me.roles
                .filter((documentRole) => documentRole.documentId === document.id)
                .map((documentRole) => this.getRoleById(documentRole.roleId))
                .filter((role) => role !== undefined),
            (role) => role!.rights)
    }

    /**
     * We test if me has the givne right on at least one document.
     * The first use is for the button "create message". We need to display in
     * case the user can create a message on at least document.
     */
    public meHasRightOnOneDocument(right: Right) {
        if (!this.me) {
            return false
        }
        if (this.me.isAdmin) {
            return true
        }
        const rolesByRight = this.getRolesByRight(right)
        return this.me.roles
            .map((dr) => this.getRoleById(dr.roleId))
            .filter((role) => role !== undefined)
            .some((role) => rolesByRight.includes(role!))
    }

    public meHasRightOnDocument(document: Document, right: Right): boolean {
        if (!this.me) {
            return false
        }
        if (this.me.isAdmin) {
            return true
        } else {
            return this.getMeRights(document).includes(right)
        }
    }

    public meHasManageUsersRight(): boolean {
        if (!this.me) {
            return false
        }
        return this.me.isAdmin
    }

    public meHasDuplicateMessageRight(document: Document) {
        return this.meHasRightOnDocument(document, DUPLICATE_MESSAGE)
    }

    public meHasManageMilestoneRight() {
        return this.meHasRightOnOneDocument(MANAGE_CAMPAIGN_NOT_STARTED)
            || this.meHasRightOnOneDocument(MANAGE_CAMPAIGN_RUNNING)
    }

    public getRolesByRight(right: Right) {
        return this.roles.filter((role) => role.rights.includes(right))
    }

    @Mutation
    public setAccounts(accounts: Array<Account>) {
        this.accounts = {}
        accounts.forEach((account: Account) => {
            this.accounts[account.id] = {
                ...account,
                creationDate: new Date(account.creationDate), // hack pour forcer le type date
            }
        })
    }

    @Mutation
    public setAccount(account?: Account) {
        if (account !== null && account !== undefined) {
            // Bug of reactivity forcing to delete the existing message before updating it.
            if (this.accounts[account.id] != null) {
                Vue.delete(this.accounts, account.id)
            }
            Vue.set(this.accounts, account.id, account)

            if (account.id === this.me!.id) {
                this.me = account
            }
        }
    }

    public getRoles(): Array<Role> {
        return this.roles
    }

    @Mutation
    public setRoles(roles: Array<Role>) {
        this.roles = roles
    }

    @Mutation
    public setMe(me: Account) {
        this.me = me
    }

    @Mutation
    public reset() {
        this.username = ''
        this.token = undefined
        this.me = undefined
        this.roles = []
        this.accounts = {}
    }

    @Mutation
    public setUsername(username: string) {
        this.username = username
    }

    @Mutation
    public setToken(token: string) {
        this.token = token
    }

    @Action
    public async loadRoles() {
        return await accountsApi.listRoles()
            .then((response: AxiosResponse<Array<Role>>) => this.setRoles(response.data))
    }

    @Action
    public async loadMe() {
        return await accountsApi.me()
            .then((response: AxiosResponse<Account>) => this.setMe(response.data))

    }

    @Action
    public async loadAccount(id: string) {
        return await accountsApi.get(id)
            .then((response: AxiosResponse<Account>) => this.setAccount(response.data))

    }

    @Action
    public async loadToken(password: string) {
        const authRequest = {
            username: this.username,
            password,
        }
        await authApi.postAuth(authRequest).then((response: AxiosResponse<AuthResponse>) => {
            const token = response.data.token
            // Set the token in the store and in the cookies
            this.setToken(token)
            Cookies.set(this.COOKIE_TOKEN, token)
            // Setup the token for the axios requests
            this.initToken()
        })
    }

    @Action
    public async loadActivationToken(token: string) {
        this.setToken(token)
        this.initToken()
    }

    @Action
    public async initToken() {
        // Load the token from the cookie if needed
        const cookie = Cookies.get(this.COOKIE_TOKEN)
        if (this.token == null && cookie != null) {
            this.setToken(cookie)
        }
        // Add the token to the requests headers
        if (this.requestInterceptor === null) {

            const requestInterceptor = globalAxios.interceptors.request.use(async (config) => {
                    const token = this.token
                    if (token) {
                        config.headers!!.Authorization = `Bearer ${token}`
                    }
                    return config
                }
                ,
                (error) => {
                    return Promise.reject(error)
                },
            )
            this.setRequestInterceptor(requestInterceptor)
        }

        // Check for token validity for each response
        if (this.responseInterceptor === null) {
            const responseInterceptor = globalAxios.interceptors.response.use(undefined, (error) => {
                const response = error.response
                // Control over the response state:
                // - undefined: corresponds to a status 403 (no token provided)
                // - status 401: the token has expired
                if (response === undefined || response.status === 401) {
                    // Reset the store and invalidate the token
                    // Modules retrieved from router instance. TODO: find a better way to retrieve it.
                    resetStore(modules)
                    this.invalidateToken()
                }
                return Promise.reject(error)
            })
            this.setResponseInterceptor(responseInterceptor)
        }
    }

    @Action
    public async invalidateToken() {
        Cookies.remove(this.COOKIE_TOKEN)
        if (this.requestInterceptor !== null) {
            globalAxios.interceptors.request.eject(this.requestInterceptor)
            this.setRequestInterceptor(null)
        }
        if (this.responseInterceptor !== null) {
            globalAxios.interceptors.response.eject(this.responseInterceptor)
            this.setResponseInterceptor(null)
        }
    }

    @Action
    public async loadAccounts() {
        await accountsApi.list().then((response: AxiosResponse<AccountPage>) => {
            this.setAccounts(response.data.items)
        })
    }

    /**
     * Update the account by calling the server endpoint.
     * @param account Account to update
     */
    @Action
    public async updateAccount(accountPatchRequest: AccountPatchRequest) {
        await accountsApi
            .update(accountPatchRequest.id, accountPatchRequest.patch)
            .then((response: AxiosResponse<Account>) => {
                this.setAccount(response.data)
            })
    }

    /**
     * Update the account by calling the server endpoint.
     * @param account Account to update
     */
    @Action
    public async askForActivationMail(accountId: string) {
        await accountsApi
            .sendActivationMail(accountId)
            .then((response: AxiosResponse<Account>) => {
                this.setAccount(response.data)
            })
    }

    /**
     * create the account by calling the server endpoint.
     * @param account Account to update
     */
    @Action
    public async createAccount(accountCreation: AccountCreation) {
        await accountsApi
            .create(accountCreation)
            .then((response: AxiosResponse<Account>) => {
                this.setAccount(response.data)
            })
    }

    @Action
    public async validateAccount(validate: Validate) {
        await accountsApi.validate(validate)
    }

    @Action
    public async resetPassword(email: AccountMail) {
        await accountsApi.resetPassword(email)
    }

    @Action
    public getAccountByActivationToken() {
        return accountsApi.getAccountByActivationToken()
    }

    public get currentActivationAccount(): Account | undefined {
        return this.currentTokenAccount
    }

    @Mutation
    public setCurrentActivationAccount(account: Account) {
        this.currentTokenAccount = account
    }

    public get currentActivationError(): number {
        return this.currentTokenError
    }

    @Mutation
    public setCurrentActivationError(error: number) {
        this.currentTokenError = error
    }

    @Mutation
    public resetCurrentActivationAccount() {
        this.currentTokenAccount = undefined
    }

    @Mutation
    public resetCurrentActivationError() {
        this.currentTokenError = 200
    }

    @Mutation
    private setRequestInterceptor(value: number | null) {
        this.requestInterceptor = value
    }

    @Mutation
    private setResponseInterceptor(value: number | null) {
        this.responseInterceptor = value
    }
}
