















import {Component, Vue, Prop} from 'vue-property-decorator'
import {Route} from 'vue-router'
import Next from '@/router/Next'
import {SaveCancelDialogInterface, SaveCancelType} from '@/store/types'

@Component
export default class SaveCancelDialog extends Vue implements SaveCancelDialogInterface {

    @Prop({default: null, type: Function}) private readonly save!: () => Promise<void>
    @Prop() private readonly canSave!: boolean
    @Prop() private readonly canCancel!: boolean

    private nextLocation: string = ''
    private ignorePopup: boolean = false
    private display: boolean = false
    private dialogType: SaveCancelType = SaveCancelType.Save
    private interrupt: boolean = false

    public popUp(to: Route, next: Next) {
        if ((this.canSave || this.canCancel) && !this.ignorePopup) {
            this.nextLocation = to.fullPath
            this.display = true
            if (this.canCancel && !this.canSave) {
                this.dialogType = SaveCancelType.Cancel
            } else {
                this.dialogType = SaveCancelType.Save
            }
        } else {
            next()
        }
    }

    public forceRedirection() {
        this.ignorePopup = true
    }

    public interruptRedirection() {
        this.interrupt = true
    }

    private get isTypeSave() {
        return this.dialogType === SaveCancelType.Save
    }

    private discardChanges() {
        this.display = false
        this.redirect()
    }

    private async saveChanges() {
        await this.save().then(() => {
            this.display = false
            if (!this.interrupt) {
                this.redirect()
            } else {
                this.interrupt = false
            }
        })
    }

    private stay() {
        this.display = false
    }

    private redirect() {
        this.forceRedirection()
        this.$router.push(this.nextLocation)
    }
}
