import {Account} from '@/openapi'
import Next from '@/router/Next'
import {resetStore} from '@/store/actions/globalActions'
import modules from '@/store/modules'
import {AxiosError, AxiosResponse} from 'axios'
import {Route} from 'vue-router'


export default function fetchActivationToken(to: Route, from: Route, next: Next) {
    const token = to.params.token

    modules.accounts.invalidateToken()
        .then(() => {
            resetStore(modules)
            modules.accounts.loadActivationToken(token)
                .then(() => {
                    modules.accounts.getAccountByActivationToken()
                        .then((response: AxiosResponse<Account>) => {
                            modules.accounts.setCurrentActivationAccount(response.data)
                            modules.accounts.resetCurrentActivationError()
                            next()
                        }).catch((error: AxiosError) => {
                            modules.accounts.resetCurrentActivationAccount()
                            modules.accounts.setCurrentActivationError(error.response!.status)
                            next()
                        },
                    )
                })
        })
}
