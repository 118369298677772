




























































































































import {Account, AccountCreation, Document, Role} from '@/openapi/api'
import {userHasRightToEditUser} from '@/store/actions/rightActions'
import modules from '@/store/modules'
import {AccountPatchRequest, SaveCancelDialogInterface} from '@/store/types'
import {Component, Prop, Vue} from 'vue-property-decorator'

interface DraftAccount {
    firstName: string | undefined
    lastName: string | undefined
    email: string | undefined
    password: string
    passwordConfirmation: string
    isAdmin: boolean | undefined
    documents: Array<Document> | undefined
    role: Role | undefined
}

@Component({})
export default class EditAccount extends Vue {
    @Prop({default: ''}) private readonly id!: string
    @Prop({default: false}) private readonly selfEditing!: boolean
    @Prop({default: false}) private readonly creating!: boolean

    private accountId: string = this.selfEditing ? this.$modules.accounts.meAccount!.id : this.id
    private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined

    private draft: DraftAccount = {
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: '',
        passwordConfirmation: '',
        isAdmin: undefined,
        documents: undefined,
        role: undefined,
    }

    private get me() {
        return this.$modules.accounts.meAccount
    }

    private get account(): Account | undefined {
        if (this.creating) {
            return undefined
        }
        return this.$modules.accounts.getAccountById(this.accountId)
    }

    private get activationMailBtnAvailable(): boolean {
        if (this.account !== undefined) {
            return this.account.firstLogin && userHasRightToEditUser(modules, this.id)
        }
        return false
    }

    private get firstName(): string {
        if (this.draft.firstName !== undefined) {
            return this.draft.firstName
        } else if (this.account !== undefined) {
            return this.account.firstName
        } else {
            return ''
        }
    }

    private set firstName(firstName: string) {
        Vue.set(this.draft, 'firstName', firstName)
    }

    private get lastName(): string {
        if (this.draft.lastName !== undefined) {
            return this.draft.lastName
        } else if (this.account !== undefined) {
            return this.account.lastName
        } else {
            return ''
        }
    }

    private set lastName(lastName: string) {
        Vue.set(this.draft, 'lastName', lastName)
    }

    private get login() {
        return this.account !== undefined ? this.account.login : ''
    }

    private get email() {
        if (this.draft.email !== undefined) {
            return this.draft.email
        } else if (this.account !== undefined) {
            return this.account.email
        } else {
            return ''
        }
    }

    private set email(email: string) {
        Vue.set(this.draft, 'email', email)
    }

    private get isAdmin(): boolean {
        if (this.draft.isAdmin !== undefined) {
            return this.draft.isAdmin
        } else if (this.account !== undefined) {
            return this.account.isAdmin
        } else {
            return false
        }
    }

    private set isAdmin(isAdmin: boolean) {
        Vue.set(this.draft, 'isAdmin', isAdmin)
    }

    private get allDocuments() {
        return this.$modules.documents.all
    }

    private get allRoles() {
        return this.$modules.accounts.allRoles
    }

    private get role(): Role | undefined {
        if (this.draft.role !== undefined) {
            return this.draft.role
        } else if (this.account !== undefined && this.account.roles.length > 0) {
            // POC : The account has the same role on every document.
            return this.$modules.accounts.getRoleById(this.account.roles[0].roleId)
        } else {
            return undefined
        }
    }

    private set role(role: Role | undefined) {
        Vue.set(this.draft, 'role', role)
    }

    private get documents(): Array<Document> | undefined {
        if (this.draft.documents !== undefined) {
            return this.draft.documents
        } else if (this.account !== undefined) {
            return this.account.roles.map((dr) => this.$modules.documents.getById(dr.documentId)!)
        } else {
            return []
        }
    }

    private set documents(documents: Array<Document> | undefined) {
        Vue.set(this.draft, 'documents', documents)
    }

    private get canSave(): boolean {
        if (!this.canCancel) {
            return false
        }

        return (
            (!this.creating || this.accountId !== '')
            && (this.firstName !== '')
            && (this.lastName !== '')
            && (this.email !== '')
            && (
                // in editing mode we must match password and password confirmation
                (!this.creating && this.draft.password.trim() === this.draft.passwordConfirmation) || this.creating
            )
            && (this.isAdmin ||
                (this.documents !== undefined && this.documents.length !== 0)
                && (this.role !== undefined))
        )
    }

    private get canCancel(): boolean {
        if (this.account === undefined) {
            return (
                (this.accountId !== '') ||
                (this.draft.email !== undefined && this.draft.email !== '') ||
                (this.draft.firstName !== undefined && this.draft.firstName !== '') ||
                (this.draft.lastName !== undefined && this.draft.lastName !== '') ||
                (this.draft.isAdmin !== undefined) ||
                (this.draft.documents !== undefined && this.draft.documents.length !== 0) ||
                (this.draft.role !== undefined)
            )
        } else {
            const docs = this.account.roles.map((dr) => this.$modules.documents.getById(dr.documentId)!)
            let rights

            if (this.account.roles.length === 0) {
                rights = []
            } else {
                rights = this.$modules.accounts.getRoleById(this.account.roles[0].roleId)
            }
            return (
                (this.draft.email !== undefined && this.draft.email !== this.account.email) ||
                (this.draft.firstName !== undefined && this.draft.firstName !== this.account.firstName) ||
                (this.draft.lastName !== undefined && this.draft.lastName !== this.account.lastName) ||
                (this.draft.isAdmin !== undefined && this.draft.isAdmin !== this.account.isAdmin) ||
                (this.draft.password !== undefined && this.draft.password !== '') ||
                (this.draft.passwordConfirmation !== undefined && this.draft.passwordConfirmation !== '') ||
                (this.draft.documents !== undefined && JSON.stringify(this.draft.documents) !== JSON.stringify(docs)) ||
                (this.draft.role !== undefined && this.draft.role !== rights)
            )
        }
    }

    private resetForm() {
        // Reset the login as well while creating
        if (this.creating) {
            this.accountId = ''
        }
        // Reset the draft
        this.draft = {
            firstName: undefined,
            lastName: undefined,
            isAdmin: undefined,
            email: undefined,
            password: '',
            passwordConfirmation: '',
            documents: undefined,
            role: undefined,
        }
    }

    private save() {
        if (this.creating) {
            const accountCreation: AccountCreation = {
                login: this.accountId,
                firstName: this.firstName,
                lastName: this.lastName,
                isAdmin: this.isAdmin,
                email: this.email,
                roles: [],
            }
            if (this.draft.role !== undefined || this.draft.documents !== undefined) {
                accountCreation.roles = this.documents!!.map((doc) => {
                    return {
                        documentId: doc.id,
                        roleId: this.role!!.id,
                    }
                })
            }
            return this.$modules.accounts.createAccount(accountCreation)
        } else {

            const patchRequest: AccountPatchRequest = {
                id: this.accountId,
                patch: {
                    firstName: this.draft.firstName,
                    lastName: this.draft.lastName,
                    isAdmin: this.draft.isAdmin,
                    email: this.draft.email,
                    password: this.draft.password,
                },
            }
            if (this.draft.role !== undefined || this.draft.documents !== undefined) {
                patchRequest.patch.roles = this.documents!!.map((doc) => {
                    return {
                        documentId: doc.id,
                        roleId: this.role!!.id,
                    }
                })
            }

            return this.$modules.accounts.updateAccount(patchRequest).then(() => {
                    this.resetForm()
                },
            )
        }

    }

    private askForMail() {
        return this.$modules.accounts.askForActivationMail(this.accountId).then(() => {
                this.resetForm()
            },
        )
    }

    private async sendActivationMail() {
        await this.askForMail()
    }

    private async saveAndPush() {
        await this.save().then(() => {
            this.saveCancelDialog!.forceRedirection()
            if (!this.selfEditing) {
                this.$router.push('/accounts')
            } else {
                this.$router.push('/messages')
            }
        })
    }

    private radio(selected: Role) {
        this.role = selected
    }

    private checkbox(selectedItems: Array<Document>) {
        this.documents = selectedItems
    }

    private mounted() {
        this.$modules.accounts.loadAccount(this.accountId)
        this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
    }

    private beforeRouteUpdate(to: any, from: any, next: any) {
        if (userHasRightToEditUser(modules, to.params.id)) {
            return next()
        } else {
            return next('/')
        }
    }

    private beforeRouteLeave(to: any, from: any, next: any) {
        this.saveCancelDialog!.popUp(to, next)
    }

    private listDocuments(): string {
        if (this.documents === undefined || this.documents.length === 0) {
            return ''
        } else {
            let documents = ''

            for (const document of this.documents) {
                documents += ', ' + document.name
            }
            return documents.substr(2)
        }
    }
}
