






















































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class EditFormRow extends Vue {
    @Prop(String) private readonly title!: string
    @Prop({default: null}) private readonly value!: string
    @Prop(Boolean) private readonly editable!: boolean
}
