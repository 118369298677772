























































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class CreatePassword extends Vue {
    @Prop(String) private readonly token!: string
    private alreadyReset: boolean = false

    private mounted() {
        this.alreadyReset = (this.$modules.accounts.currentActivationError === 401)
    }
}
