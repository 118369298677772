import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VFlex,{staticClass:"mt-1 date-picker",attrs:{"sm":"10","md":"6"}},[_c(VTextField,_vm._g({attrs:{"autocomplete":"off","value":_vm.datesRangeDisplay,"placeholder":_vm.$t('calendar.label.dates-placeholder')}},on))],1),_c(VFlex,{staticClass:"mt-n1 pa-0",attrs:{"sm":"2","md":"6"}},[_vm._t("error")],2)]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c(VDatePicker,{attrs:{"locale":_vm.$i18n.locale,"first-day-of-week":_vm.$t('first-day-of-week'),"range":"","no-title":"","color":"primary","allowed-dates":_vm.allowedDate,"events":_vm.eventDatesArray,"event-color":"red lighten-1"},on:{"input":_vm.updateRange,"update:picker-date":_vm.eventDates},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c(VSpacer),_c(VBtn,{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(_vm._s(_vm.$t('calendar.label.close')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }