import Next from '@/router/Next'
import {userHasRightToEditUser} from '@/store/actions/rightActions'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export default function checkRightEditUser(to: Route, from: Route, next: Next) {
    const accountId = to.params.id
    if (userHasRightToEditUser(modules, accountId)) {
        next()
    } else {
        next(false)
    }
}

