































































































import {Role} from '@/openapi'
import {
    CREATE_MESSAGE,
    DELETE_CAMPAIGN_NOT_STARTED,
    DELETE_CAMPAIGN_RUNNING,
    DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED,
    DELETE_MESSAGE_NOT_VALIDATED_RUNNING,
    DELETE_MESSAGE_VALIDATED_NOT_STARTED,
    DELETE_MESSAGE_VALIDATED_RUNNING,
    MANAGE_CAMPAIGN_NOT_STARTED,
    MANAGE_CAMPAIGN_RUNNING,
    MANAGE_EXPORTS,
    MANAGE_USERS,
    MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED,
    MODIFY_MESSAGE_NOT_VALIDATED_RUNNING,
    MODIFY_MESSAGE_VALIDATED_NOT_STARTED,
    MODIFY_MESSAGE_VALIDATED_RUNNING,
    READ_MESSAGE,
    VALIDATE_MESSAGE_NOT_STARTED,
    VALIDATE_MESSAGE_RUNNING,
} from '@/store/Right'
import {Component, Vue} from 'vue-property-decorator'

interface Section {
    display: string,
    colspan?: number,
    primary?: string,
    secondary?: string,
    rights?: Array<{
        display: string,
        primary: string,
        secondary?: string,
    }>
}

@Component
export default class AllRoles extends Vue {
    private headers = [
        {
            text: 'Action',
            align: 'left',
            sortable: false,
            value: 'name',
        },
        ...this.roles.map((role) => {
            return {
                text: role.name,
                align: 'center',
                sortable: false,
                value: role.id,
            }
        }),
    ]

    private sections: Array<Section> = [
        {
            display: this.TValueOf('all-roles.sections.not-started-campaign'),
            colspan: 4,
            rights: [
                {
                    display: this.TValueOf('all-roles.rights.create-message'),
                    primary: CREATE_MESSAGE,
                },
                {
                    display: this.TValueOf('all-roles.rights.edit-message'),
                    primary: MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED,
                    secondary: MODIFY_MESSAGE_VALIDATED_NOT_STARTED,
                },
                {
                    display: this.TValueOf('all-roles.rights.delete-message'),
                    primary: DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED,
                    secondary: DELETE_MESSAGE_VALIDATED_NOT_STARTED,
                },
                {
                    display: this.TValueOf('all-roles.rights.validate-message'),
                    primary: VALIDATE_MESSAGE_NOT_STARTED,
                },
                {
                    display: this.TValueOf('all-roles.rights.manage-campaign'),
                    primary: MANAGE_CAMPAIGN_NOT_STARTED,
                },
                {
                    display: this.TValueOf('all-roles.rights.delete-campaign'),
                    primary: DELETE_CAMPAIGN_NOT_STARTED,
                },
            ],
        },
        {
            display: this.TValueOf('all-roles.sections.running-campaign'),
            colspan: 4,
            rights: [
                {
                    display: this.TValueOf('all-roles.rights.create-message'),
                    primary: CREATE_MESSAGE,
                },
                {
                    display: this.TValueOf('all-roles.rights.edit-message'),
                    primary: MODIFY_MESSAGE_NOT_VALIDATED_RUNNING,
                    secondary: MODIFY_MESSAGE_VALIDATED_RUNNING,
                },
                {
                    display: this.TValueOf('all-roles.rights.delete-message'),
                    primary: DELETE_MESSAGE_NOT_VALIDATED_RUNNING,
                    secondary: DELETE_MESSAGE_VALIDATED_RUNNING,
                },
                {
                    display: this.TValueOf('all-roles.rights.validate-message'),
                    primary: VALIDATE_MESSAGE_RUNNING,
                },
                {
                    display: this.TValueOf('all-roles.rights.manage-campaign'),
                    primary: MANAGE_CAMPAIGN_RUNNING,
                },
                {
                    display: this.TValueOf('all-roles.rights.delete-campaign'),
                    primary: DELETE_CAMPAIGN_RUNNING,
                },
            ],
        },
        {
            display: this.TValueOf('all-roles.sections.manage-users'),
            primary: MANAGE_USERS,
        },
        {
            display: this.TValueOf('all-roles.sections.manage-roles'),
            primary: READ_MESSAGE, // No right for it atm so the most basic right is used
        },
        {
            display: this.TValueOf('all-roles.sections.manage-exports'),
            primary: MANAGE_EXPORTS,
        }]

    private get roles(): Array<Role> {
        return [...this.$modules.accounts.allRoles,
            {
                id: '',
                name: this.TValueOf('edit-form.user-admin-privilege'),
                rights: [],
            },
        ]
    }


    private roleHasRight(role: Role, right: string) {
        if (role.name === this.TValueOf('edit-form.user-admin-privilege')) {
            return true
        }
        return role.rights.includes(right)
    }

    private TValueOf(key: string): string {
        return this.$t(key) as string
    }
}
