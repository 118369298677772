import Next from '@/router/Next'
import {
    userHasRightToCreateMilestone,
    userHasRightToDeleteMilestone,
    userHasRightToEditMilestone,
} from '@/store/actions/rightActions'
import modules, {Modules} from '@/store/modules'
import {Route} from 'vue-router'

export default function checkRightManageMilestone(to: Route, from: Route, next: Next) {
    const milestoneId = to.params.milestoneId
    if (milestoneId === undefined && userHasRightToCreateMilestone(modules)
        || userHasRightToEditMilestone(modules, milestoneId)
        || userHasRightToDeleteMilestone(modules, milestoneId)) {
        next()
    } else {
        next('/campaigns')
    }


}
