
























































import {Component, Prop, Vue} from 'vue-property-decorator'

interface TextSegment {
    name: string
    order: number
    criteria: Array<string>
}

interface TextConflict {
    name: string
    segments: Array<TextSegment>
}

@Component
export default class CampaignExportDialog extends Vue {

    @Prop() private display!: boolean
    @Prop({default: null, type: Function}) private readonly confirmAction!: () => void

}
