








































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class EditFormStepper extends Vue {

    @Prop({ default: [] }) private readonly steps!: Array<string>
    @Prop({ default: 100}) private readonly stepPtr!: number
}
