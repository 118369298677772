import Next from '@/router/Next'
import modules from '@/store/modules'
import {Route} from 'vue-router'


interface Options {
    step: number
}

export function loadMessages(to: Route, from: Route, next: Next) {
    modules.messages.loadMessages().then(() => {
        next()
    })
}


export function loadCurrentMessage(options: Options) {
    return (to: Route, from: Route, next: Next) => {
        const id = to.params.id
        modules.currentMessage.loadMessage(id)
            .then(() => {
                modules.currentMessage.setStep(options.step)
                modules.currentMessage.resetDraft()
                const docId = modules.currentMessage.draftAndCurrent.documentId!
                const doc = modules.documents.getById(docId)
                if (doc !== undefined) {
                    modules.documents.loadImage(docId)
                } else {
                    modules.documents.resetCurrentDocument()
                }
            })
            .then(() => next())
            .catch(() => next('/'))
    }
}

export async function loadOriginalMessage(to: Route, from: Route, next: Next) {
    const id = to.params.id
    modules.currentMessage.reset()
    await modules.currentMessage.loadOriginalMessage(id)
        .then(() => {
            modules.currentMessage.setStep(0)
            const docId = modules.currentMessage.draftAndCurrent.documentId!
            const doc = modules.documents.getById(docId)
            if (doc !== undefined) {
                modules.documents.loadImage(docId)
            } else {
                modules.documents.resetCurrentDocument()
            }
        })
        .then(() => next())
        .catch(() => next('/'))

}

export function unloadCurrentMessage(to: Route, from: Route, next: Next) {
    modules.currentMessage.unloadCurrentMessage()
    modules.documents.resetCurrentDocument()
    next()
}
