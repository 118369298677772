





































































































import {Document, MilestoneState, Milestone, MilestoneCreate, MilestonePatch} from '@/openapi'
import {userHasRightToDeleteMilestone, userHasRightToEditMilestone} from '@/store/actions/rightActions'
import {MANAGE_CAMPAIGN_NOT_STARTED, MANAGE_CAMPAIGN_RUNNING} from '@/store/Right'
import {Component, Prop, Vue} from 'vue-property-decorator'
import {SaveCancelDialogInterface} from '@/store/types'

@Component
export default class EditCampaign extends Vue {

    @Prop(String) private readonly milestoneId!: string | undefined
    private milestoneDocument: Document | null = null
    private milestoneName: string = ''
    private milestoneDates: Array<string> = []
    private milestone: Milestone | undefined = undefined
    private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined
    private hasConflict: boolean = false

    private get creating(): boolean {
        return this.milestoneId === undefined
    }

    private get milestones(): Array<Milestone> {
        return this.$modules.milestones.all
    }

    private get documents(): Array<Document> {
        return this.$modules.documents.all.filter((document) => {
            return this.$modules.accounts.meHasRightOnDocument(document, MANAGE_CAMPAIGN_NOT_STARTED)
                || this.$modules.accounts.meHasRightOnDocument(document, MANAGE_CAMPAIGN_RUNNING)
        })
    }

    /**
     * Compute the dates which are not allowed for the current milestone based on the milestones on the same document.
     */
    private get notAllowedDates(): Array<{ from: string, to: string }> {
        return this.milestones
            .filter((milestone) => {
                return (this.milestoneDocument == null || milestone.documentId === this.milestoneDocument.id)
                    && milestone.id !== this.milestoneId
            })
            .map((milestone) => {
                return {
                    from: milestone.dateStart,
                    to: milestone.dateEnd,
                }
            })
    }

    private milestoneById(milestoneId: string) {
        return this.$modules.milestones.getById(milestoneId)
    }

    private documentById(documentId: string) {
        return this.$modules.documents.getById(documentId)
    }

    private mounted() {
        this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
        // For edition only: load the current state of the milestone.
        if (!this.creating) {
            this.milestone = this.milestoneById(this.milestoneId!)

            if (this.milestone !== undefined) {
                const document = this.documentById(this.milestone.documentId)
                if (document !== undefined) {
                    this.milestoneDocument = document
                } else {
                    this.milestoneDocument = null
                }
                this.milestoneName = this.milestone.name
                this.milestoneDates = [this.milestone.dateStart, this.milestone.dateEnd]
            }
        }
    }

    /**
     * Update the range (starting and ending date of milestone) by checking its validity regarding the non-overlapping
     * milestone policy.
     */
    private updateRange() {
        this.milestoneDates.sort((a: string, b: string) => {
            return (Date.parse(a) > Date.parse(b)) ? 1 : -1
        })

        const date: Date = new Date(this.milestoneDates[0])
        const toDate: Date = new Date(Date.parse(this.milestoneDates[1]))
        // Set the same time for both hours to avoid time issues when comparing dates of different timezones.
        date.setHours(23, 59, 59, 999)
        toDate.setHours(23, 59, 59, 999)

        while (date <= toDate) {
            if (this.allowedDate(date)) {
                this.milestoneDates[1] = date.toISOString().split('T')[0]
            } else {
                // If the range selected contains not allowed dates, then the range is set to the starting day only.
                this.milestoneDates[1] = this.milestoneDates[0]
                break
            }
            date.setDate(date.getDate() + 1)
        }
    }

    /**
     * Check whether the date is allowed or not.
     * @param date Date to be checked.
     */
    private allowedDate(date: Date) {
        const time = date.getTime()
        for (const notAllowedRange of this.notAllowedDates) {
            if (time >= Date.parse(notAllowedRange.from) && time <= Date.parse(notAllowedRange.to)) {
                return false
            }
        }
        return true
    }

    private get canCancel(): boolean {
        if (this.creating) {
            return this.milestoneName !== '' || this.milestoneDocument != null || this.milestoneDates.length > 0
        } else {
            return this.milestone !== undefined && (this.milestoneName !== this.milestone!.name
                || this.milestoneDocument!.id !== this.milestone!.documentId
                || this.milestoneDates[0] !== this.milestone!.dateStart
                || this.milestoneDates[1] !== this.milestone!.dateEnd)
        }
    }

    private cancel() {
        if (this.creating) {
            this.milestoneName = ''
            this.milestoneDocument = null
            this.milestoneDates = []
        } else {
            this.milestoneName = this.milestone!.name
            const document = this.documentById(this.milestone!.documentId)
            this.milestoneDocument = document !== undefined ? document : null
            this.milestoneDates = [this.milestone!.dateStart, this.milestone!.dateEnd]
        }
    }

    private displaySave(): boolean {
        if (!this.creating) {
            return userHasRightToEditMilestone(this.$modules, this.milestoneId!!)
        }
        return true
    }

    private get canSave(): boolean {
        return this.milestoneName !== '' && this.milestoneDocument != null && this.milestoneDates.length === 2
            && (this.creating || this.milestone !== undefined && (this.milestone.name !== this.milestoneName ||
                this.milestone.documentId !== this.milestoneDocument.id
                || this.milestone.dateStart !== this.milestoneDates[0]
                || this.milestone.dateEnd !== this.milestoneDates[1]))
    }

    private async save() {
        if (this.creating) {
            const milestoneCreate: MilestoneCreate = {
                name: this.milestoneName!,
                documentId: this.milestoneDocument!.id,
                dateStart: this.milestoneDates[0],
                dateEnd: this.milestoneDates[1],
            }

            await this.$modules.milestones.createMilestone(milestoneCreate).then(() => {
                this.hasConflict = (this.$modules.milestones.status === 409)
                if (this.hasConflict) {
                    this.saveCancelDialog!.interruptRedirection()
                }
            })
        } else {
            const milestonePatch: MilestonePatch = {
                name: this.milestoneName !== this.milestone!.name ? this.milestoneName! : undefined,
                dateStart: this.milestoneDates[0] !== this.milestone!.dateStart ? this.milestoneDates[0] : undefined,
                dateEnd: this.milestoneDates[1] !== this.milestone!.dateEnd ? this.milestoneDates[1] : undefined,
            }

            await this.$modules.milestones.updateMilestone({
                milestoneId: this.milestoneId!,
                milestonePatch,
            }).then(() => {
                this.hasConflict = (this.$modules.milestones.status === 409)
                if (this.hasConflict) {
                    this.saveCancelDialog!.interruptRedirection()
                }
            })
        }
    }

    private async saveAndPush() {
        await this.save()
        if (!this.hasConflict) {
            this.saveCancelDialog!.forceRedirection()
            this.$router.push('/campaigns')
        }
    }

    private displayDelete(): boolean {
        if (this.creating) {
            return false
        }
        return userHasRightToDeleteMilestone(this.$modules, this.milestoneId!!)
    }

    private get canDelete(): boolean {
        if (this.creating) {
            return false
        }
        // Check whether the milestone has any message
        return this.$modules.messages.all.filter((message) => {
            return message.milestoneId === this.milestoneId!
        }).length === 0
    }

    private deleteMilestone() {
        if (this.creating) {
            return
        }
        this.$modules.milestones.removeMilestone(this.milestoneId!).then(() => {
            this.saveCancelDialog!.forceRedirection()
            this.$router.push('/campaigns')
        })
    }

    private get hasRightToSetDates(): boolean {
        if (this.milestoneDocument == null || this.milestoneDates.length < 2) {
            return true
        }
        const milestoneState: MilestoneState = this.$modules.milestones.milestoneStateFromDates(this.milestoneDates[0]
            , this.milestoneDates[1])
        switch (milestoneState) {
            case MilestoneState.NotStarted:
                return this.$modules.accounts.meHasRightOnDocument(this.milestoneDocument,
                    MANAGE_CAMPAIGN_NOT_STARTED)
            case MilestoneState.Running:
                return this.$modules.accounts.meHasRightOnDocument(this.milestoneDocument, MANAGE_CAMPAIGN_RUNNING)
            case MilestoneState.Finished:
                return false
        }
    }

    private radio(selected: Milestone) {
        this.milestone = selected
    }

    private beforeRouteLeave(to: any, from: any, next: any) {
        this.saveCancelDialog!.popUp(to, next)
    }

    private async closePopUp() {
        await this.$modules.milestones.loadMilestones()
        if (this.milestone!.dateStart !== undefined && this.milestone!.dateEnd !== undefined) {
            this.milestoneDates = [this.milestone!.dateStart, this.milestone!.dateEnd]
        } else {
            this.milestoneDates = []
        }
        this.hasConflict = false
    }
}
