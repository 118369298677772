

































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class MessageValidation extends Vue {
    private selected = []
    @Prop(Array) private readonly availableWorkflows!: Array<string>
    @Prop(Array) private readonly availableValidators!: Array<string>
}
