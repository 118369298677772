import Next from '@/router/Next'
import {userHasRightToManageExports} from '@/store/actions/rightActions'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export default function checkRightManageExports(to: Route, from: Route, next: Next) {
    const documents = modules.documents.all
    let found = false
    for (const document of documents) {
        if (userHasRightToManageExports(modules, document.id)) {
            found = true
            break
        }
    }
    if (found) {
        next()
    } else {
        // Redirect to all messages page if needed.
        next('/')
    }

}
