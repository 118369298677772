// Should be kept in sync with : lib/common/src/main/kotlin/com/kami/outside/common/enums/Right.kt

export type Right = string

export const READ_MESSAGE: Right = 'READ_MESSAGE'
export const CREATE_MESSAGE: Right = 'CREATE_MESSAGE'
export const DUPLICATE_MESSAGE: Right = 'DUPLICATE_MESSAGE'
export const MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED: Right = 'MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED'
export const MODIFY_MESSAGE_VALIDATED_NOT_STARTED: Right = 'MODIFY_MESSAGE_VALIDATED_NOT_STARTED'
export const MODIFY_MESSAGE_NOT_VALIDATED_RUNNING: Right = 'MODIFY_MESSAGE_NOT_VALIDATED_RUNNING'
export const MODIFY_MESSAGE_VALIDATED_RUNNING: Right = 'MODIFY_MESSAGE_VALIDATED_RUNNING'
export const DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED: Right = 'DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED'
export const DELETE_MESSAGE_VALIDATED_NOT_STARTED: Right = 'DELETE_MESSAGE_VALIDATED_NOT_STARTED'
export const DELETE_MESSAGE_NOT_VALIDATED_RUNNING: Right = 'DELETE_MESSAGE_NOT_VALIDATED_RUNNING'
export const DELETE_MESSAGE_VALIDATED_RUNNING: Right = 'DELETE_MESSAGE_VALIDATED_RUNNING'
export const DELETE_MESSAGE_FINISHED: Right = 'DELETE_MESSAGE_FINISHED'
export const VALIDATE_MESSAGE_NOT_STARTED: Right = 'VALIDATE_MESSAGE_NOT_STARTED'
export const VALIDATE_MESSAGE_RUNNING: Right = 'VALIDATE_MESSAGE_RUNNING'
export const MANAGE_CAMPAIGN_NOT_STARTED: Right = 'MANAGE_CAMPAIGN_NOT_STARTED'
export const MANAGE_CAMPAIGN_RUNNING: Right = 'MANAGE_CAMPAIGN_RUNNING'
export const DELETE_CAMPAIGN_NOT_STARTED: Right = 'DELETE_CAMPAIGN_NOT_STARTED'
export const DELETE_CAMPAIGN_RUNNING: Right = 'DELETE_CAMPAIGN_RUNNING'
export const DELETE_CAMPAIGN_FINISHED: Right = 'DELETE_CAMPAIGN_FINISHED'
export const MANAGE_USERS: Right = 'MANAGE_USERS'
export const MANAGE_EXPORTS: Right = 'MANAGE_EXPORTS'
