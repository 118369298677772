








































import {Component, Prop, Vue} from 'vue-property-decorator'
import {SaveCancelDialogInterface} from '@/store/types'
import {Route} from 'vue-router'
import Next from '@/router/Next'

@Component({})
export default class CreateMessageType extends Vue {

    @Prop(String) private readonly id!: string
    private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined
    private isUnique = true

    get currentState() {
        return this.$modules.currentMessage.currentStep
    }

    private get milestones() {
        return this.$modules.milestones.all
    }

    private get canSave() {
        return this.$modules.currentMessage.isDraftValid
    }

    private get canCancel(): boolean {
        return (
            (this.$modules.currentMessage.draft.name !== undefined)
            || (this.$modules.currentMessage.draft.documentId !== undefined)
        )
    }

    private async save() {
        await this.$modules.currentMessage.saveStep().then(() => {
                this.isUnique = (this.$modules.currentMessage.status !== 409)
                if (!this.isUnique) {
                    this.saveCancelDialog!.interruptRedirection()
                }
            },
        )
    }

    private async saveAndPush() {
        await this.save()
        if (this.isUnique) {
            this.saveCancelDialog!.forceRedirection()
            this.$router.push('/messages')
        }
    }

    private async saveNext() {
        await this.save()
        if (this.isUnique) {
            this.saveCancelDialog!.forceRedirection()
            this.next()
        }
    }

    private async next() {
        const message = this.$modules.currentMessage.current!
        await this.$router.push(`/create-message/${message.id}/step-2-draft`)
    }

    private get canNext() {
        // Next button is shown only if the message is at step 2 draft
        return this.currentState > 0
    }

    private beforeRouteLeave(to: Route, from: Route, next: Next) {
        this.saveCancelDialog!.popUp(to, next)
    }

    private mounted() {
        this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
    }

    private setIsUnique(isUniqueTitle: boolean) {
        this.isUnique = isUniqueTitle
    }

    private get errorMessage(): string | undefined {
        if (!this.isUnique) {
            return 'edit-form.error-message-title-exists'
        }
    }
}
