










import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class Todo extends Vue {}
