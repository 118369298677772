
























import {Component, Vue} from 'vue-property-decorator'

Component.registerHooks(['beforeRouteEnter', 'beforeRouteLeave', 'beforeRouteUpdate'])

@Component
export default class App extends Vue {

}
