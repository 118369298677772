






























































import {SaveCancelDialogInterface} from '@/store/types'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class CreateMessageDraft extends Vue {

    @Prop(String) private readonly id!: string
    private isContained = true
    private dialogPreview = false
    private saveCancelDialog: SaveCancelDialogInterface | undefined = undefined

    get currentState() {
        return this.$modules.currentMessage.currentStep
    }

    private get messageName() {
        const message = this.$modules.currentMessage.current
        return message !== undefined ? message.name : undefined
    }

    private get document() {
        const message = this.$modules.currentMessage.current
        return this.$modules.documents.getById(message!.documentId!)
    }

    private get documents() {
        return this.$modules.documents.all
    }

    private get milestones() {
        return this.$modules.milestones.all
    }

    private get canSave() {
        return this.$modules.currentMessage.isDraftValid && this.isContained
    }

    private setIsContained(isContained: boolean) {
        this.isContained = isContained
    }

    private previous() {
        const message = this.$modules.currentMessage.current!
        this.$router.push(`/create-message/${message.id}/step-1-type`)
    }

    private async save() {
        await this.$modules.currentMessage.saveStep()
    }

    private async saveAndPush() {
        await this.save()
        this.saveCancelDialog!.forceRedirection()
        this.$router.push('/messages')
    }

    private async saveNext() {
        await this.$modules.currentMessage.saveStep()
        this.next()
    }

    private async next() {
        const message = this.$modules.currentMessage.current!
        this.$router.push(`/create-message/${message.id}/step-3-conditions`)
    }

    private get canNext() {
        // Next button is shown only if the message is at step 3 conditions
        return this.currentState > 1
    }

    private beforeRouteLeave(to: any, from: any, next: any) {
        this.saveCancelDialog!.popUp(to, next)
    }

    private mounted() {
        this.saveCancelDialog = this.$refs.saveCancelDialog as unknown as SaveCancelDialogInterface
    }
}
