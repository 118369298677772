


























































































































































import {Document} from '@/openapi/api'
import {
    userHasRightToCreateMilestone,
    userHasRightToDeleteMilestone,
    userHasRightToEditMilestone,
} from '@/store/actions/rightActions'
import {Component, Vue, Watch} from 'vue-property-decorator'

interface Campaign {
    id: string
    name: string
    start: string
    end: string
    color: string
    editable: boolean
}

@Component
export default class AllCampaigns extends Vue {
    private selectedDocument: Document = this.documents[0]
    private today: string = this.formatDate(new Date())
    private focus: string = this.formatDate(new Date())
    private start: any = null
    private end: any = null
    private height = '600'
    private calendarClass = 'fiveRows'
    private expandable = false
    private docsColor: Array<string> = ['#A4C752', '#FFA77D']
    private milestonesColor: { [key: string]: string } = {}

    private allDocumentsLabel(): string {
        return this.$t('edit-form.all-document-select-label') as string
    }

    private get documents(): Array<Document> {
        return [{
            id: '',
            name: this.allDocumentsLabel(),
            frameWidth: 0,
            frameHeight: 0,
            frameX: 0,
            frameY: 0,
            imageId: '',
            fontsId: [],
        },
            ...this.$modules.documents.all,
        ]
    }

    private get campaigns(): Array<Campaign> {
        const id = this.selectedDocument.id
        let milestones = []

        if (id !== '') {
            milestones = this.$modules.milestones.milestonesWithDocumentId(id).map((milestone) => {
                return {
                    id: milestone.id,
                    name: milestone.name,
                    start: milestone.dateStart,
                    end: milestone.dateEnd,
                    documentId: milestone.documentId,
                }
            })
        } else {
            milestones = this.$modules.milestones.all.map((milestone) => {
                return {
                    id: milestone.id,
                    name: milestone.name,
                    start: milestone.dateStart,
                    end: milestone.dateEnd,
                    documentId: milestone.documentId,
                }
            })
        }
        return milestones.map((milestone) => {
            const today = new Date()
            const endDate = new Date(milestone.end)
            today.setHours(0, 0, 0, 0)
            endDate.setHours(23, 59, 59, 999)
            const isEditable = endDate.getTime() > today.getTime()
            return {
                id: milestone.id,
                name: milestone.name,
                start: milestone.start,
                end: milestone.end,
                color: isEditable ? this.milestonesColor[milestone.documentId] : 'grey',
                editable: isEditable,
            }
        })
    }

    private get title() {
        const {start} = this
        if (!start) {
            return ''
        }
        const startMonth = this.monthFormatter(start)
        const startYear = start.year
        return `${startMonth} ${startYear}`
    }

    private get monthFormatter() {
        return (this.$refs.calendar as any).getFormatter({
            timeZone: 'UTC', month: 'long',
        })
    }

    private created() {
        this.$modules.documents.all.forEach((document, index) => {
            this.milestonesColor[document.id] = this.docsColor[index % this.docsColor.length]
        })
    }

    private mounted() {
        (this.$refs.calendar as any).checkChange()
        this.correctCampaignButtonCursor()
    }

    private correctCampaignButtonCursor() {
        const campaignsButtons = document.querySelectorAll('.v-event')
        for (const campaignsButton of campaignsButtons) {
            for (const campaign of this.campaigns) {
                if (campaign.name === campaignsButton.textContent) {
                    if (!userHasRightToEditMilestone(this.$modules, campaign.id)) {
                        campaignsButton.classList.add('forbidden-v-event')
                    }
                }
            }
        }
    }

    @Watch('title')
    private updateExpandable() {
        this.expandable = false
        const {start} = this
        // Retrieve current day
        const currentDay: string = start.date
        const currentDayComps: Array<string> = currentDay.split('-')
        const currentDayYear: string = currentDayComps[0]
        const currentDayMonth: string = currentDayComps[1]
        const currentDayDay: string = currentDayComps[2]

        // Retrieve previous and next months
        const startDate: Date = new Date(currentDayYear + '-' + currentDayMonth + '-01 00:00:00.000')
        startDate.setDate(startDate.getDate() - 1)
        let endDate: Date = new Date(currentDayYear + '-' + currentDayMonth + '-27 23:59:59.999')
        endDate.setDate(endDate.getDate() + 5)
        endDate = new Date(endDate.setDate(1))

        // Calculate number of days displayed
        while (startDate.getDay() !== 1) {
            startDate.setDate(startDate.getDate() - 1)
        }
        while (endDate.getDay() !== 0) {
            endDate.setDate(endDate.getDate() + 1)
        }

        // Calculate number of lines displayed
        const nbDays = Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24))
        const nbLines = nbDays / 7

        // Set max height considering the number of lines displayed
        if (nbLines <= 5) {
            this.calendarClass = 'fiveRows'
        } else {
            this.calendarClass = 'sixRows'
        }

        // Retrieve all days where there are more than two milestones
        const campaigns = this.campaigns.filter((campaign) => {
            return new Date(campaign.end) >= startDate && new Date(campaign.start) <= endDate
        })

        // If one displayed day has more than two milestones, display the expand button
        const currentDate = startDate
        while (currentDate <= endDate) {
            let nbCampaigns = 0
            campaigns.forEach((campaign) => {
                const campaignStart = new Date(campaign.start)
                const campaignEnd = new Date(campaign.end)
                const campaignDay = campaignStart
                campaignDay.setHours(0)
                campaignStart.setHours(0)
                campaignEnd.setHours(23, 59, 59, 999)

                // Check if there is a milestone on this day
                while (campaignDay <= campaignEnd) {
                    if (campaignDay <= currentDate && campaignDay >= currentDate) {
                        nbCampaigns += 1
                        break
                    }
                    campaignDay.setDate(campaignDay.getDate() + 1)
                }
            })
            /* If the calendar displays 5 rows, the limit of events displayed is 3 per day.
               Beyond 5 rows, we can only display 2 events per day */
            if (nbLines <= 5) {
                if (nbCampaigns > 3) {
                    this.expandable = true
                }
            } else {
                if (nbCampaigns > 2) {
                    this.expandable = true
                }
            }
            currentDate.setDate(startDate.getDate() + 1)
        }
    }

    private expandCalendar() {
        if (this.height === '600') {
            this.height = ''
        } else {
            this.height = '600'
        }
    }

    private setToday() {
        this.focus = this.today
    }

    private prev() {
        (this.$refs.calendar as any).prev()
    }

    private next() {
        (this.$refs.calendar as any).next()
    }

    private showEvent({nativeEvent, event}: { nativeEvent: Event, event: Campaign }) {
        nativeEvent.stopPropagation()

        if (userHasRightToEditMilestone(this.$modules, event.id)
            || userHasRightToDeleteMilestone(this.$modules, event.id)) {
            this.$router.push('/campaigns/' + event.id)
        }
    }

    private updateRange({start, end}: { start: string, end: string }) {
        this.start = start
        this.end = end
        setTimeout(() => {
            this.correctCampaignButtonCursor()
        }, 100)

    }

    private formatDate(date: Date): string {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0]
    }

    private hasRightToCreateCampaign() {
        return userHasRightToCreateMilestone(this.$modules)
    }

    private getEventColor(event: any) {
        return event.color
    }
}
