








































































import {Account} from '@/openapi/api'
import {AccountPatchRequest} from '@/store/types'
import {Component, Vue} from 'vue-property-decorator'
import { CREATE_MESSAGE } from '@/store/Right'
import { tableFooter } from '@/plugins/i18n'

@Component({})
export default class AllAccounts extends Vue {
    private pagination = {
        rowsPerPage: 10,
    }

    private sortBy = ['login']
    private sortDesc = [false, true]

    private get headers() {
        return [
            {text: this.$t('account.list.login'), value: 'login'},
            {text: this.$t('account.list.lastName'), value: 'lastName'},
            {text: this.$t('account.list.firstName'), value: 'firstName'},
            // {text: this.$t('account.list.role'), value: 'role'},
            {text: this.$t('account.list.status'), value: 'status'},
            {text: '', value: 'actions', sortable: false},
        ]
    }

    private get footer() {
        return tableFooter((key: string) => this.$t(key))
    }

    private selected = []
    private displayDisableMessage = false
    private selectedAccount: Account | undefined = undefined

    private get accounts(): Array<Account> {
        return this.$modules.accounts.allAccounts.map((account) => ({
            ...account,
            role: this.getRoleName(account),
        }))
    }

    private getRoleName(account: Account): string {
        if (account.roles === undefined || account.roles.length === 0) {
            return ''
        }
        // POC : The account has the same role on every document.
        const roleId = account.roles[0].roleId
        return this.$modules.accounts.getRoleById(roleId)!!.name
    }

    private get me() {
        return this.$modules.accounts.meAccount
    }

    private get canCreateMessage() {
        return this.$modules.accounts.meHasRightOnOneDocument(CREATE_MESSAGE)
    }

    private confirmDisable(account: Account) {
        if (account.active === true) {
            this.displayDisableMessage = true
            this.selectedAccount = account
        } else {
            account.active = true
            this.updateAccountActive(account)
        }
    }

    private disable() {
        this.selectedAccount!.active = false
        this.updateAccountActive(this.selectedAccount!)
        this.cancelDisable()
    }

    private cancelDisable() {
        this.displayDisableMessage = false
        this.selectedAccount = undefined
    }

    private async updateAccountActive(account: Account) {
        const patchRequest: AccountPatchRequest = {
            id: account.id,
            patch: {
                active: account.active,
            },
        }

        await this.$modules.accounts.updateAccount(patchRequest)
    }

    private refreshData() {
        this.sortBy = []
        this.sortDesc = []
    }

}
