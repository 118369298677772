

































import Dialog from '@/ui/atoms/Dialog.vue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({
    components: {Dialog},
})
export default class EditFormTitleEdit extends Vue {

    @Prop(String) private readonly label!: string
    @Prop(String) private readonly value!: string
    @Prop(String) private readonly placeholder!: string
    @Prop({default: 'text'}) private readonly type!: string
    @Prop({default: ''}) private readonly cssClass!: string

    private get title() {
        return this.value
    }

    private set title(title) {
        this.$emit('input', title)
    }
}
