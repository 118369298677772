import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c(VCard,{staticClass:"mx-auto padded",attrs:{"max-width":"600"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"white--text",attrs:{"height":"200px","src":require("@/ui/assets/logo-KAMI-outside-RVB-WEB-transparent.png"),"alt":"Logo Kami Outside"}})]),_c(VCardText,[_c(VLayout,[_c(VFlex,[_c(VTextField,{attrs:{"label":_vm.$t('login.login'),"id":"username"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleLogin.apply(null, arguments)}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"label":_vm.$t('login.password'),"type":"password","id":"password"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleLogin.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"text-right"},[_c('a',{attrs:{"href":"/#/forgotten-password"}},[_vm._v(_vm._s(_vm.$t('login.forgotten-password')))])])],1)],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c(VBtn,{attrs:{"color":"success","id":"connexionButton","disabled":_vm.locked},on:{"click":_vm.handleLogin}},[_vm._v(" "+_vm._s(_vm.$t('login.connection'))+" ")])],1),(_vm.tErrorMessage)?_c(VCardText,[_c('p',{staticClass:"error-frame text-center v-size--small"},[_vm._v(" "+_vm._s(_vm.$t(_vm.tErrorMessage))+" ")])]):_vm._e(),_c(VCardText,{staticClass:"text-center"},[_c(VBtn,{attrs:{"text":"","small":""}},[_vm._v(" "+_vm._s(_vm.$t('login.gcu'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }