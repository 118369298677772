

















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class WarningErrorSpan extends Vue {

    @Prop(String) private readonly icon!: string
    @Prop(String) private readonly type!: string
    @Prop(String) private readonly message!: string
}
