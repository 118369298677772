import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":_vm.maxWidth},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline error",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.title))}}),_c('div',{staticClass:"popupContent"},[(_vm.subTitle != null)?_c(VCardTitle,{staticClass:"subtitle-1",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.subTitle))}}):_vm._e(),(_vm.content != null)?_c(VCardText,{staticClass:"subtitle-2",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.content))}}):_vm._e(),_c(VCardActions,{staticClass:"buttonLine"},[_c(VSpacer),(_vm.cancelButtonText)?_c('IconButton',{staticClass:"cancelButton",attrs:{"leftIcon":_vm.cancelButtonIcon},on:{"click":_vm.cancelAction}},[_vm._v(" "+_vm._s(_vm.$t(_vm.cancelButtonText))+" ")]):_vm._e(),(_vm.confirmButtonText)?_c('IconButton',{staticClass:"confirmButton",attrs:{"leftIcon":_vm.confirmButtonIcon},on:{"click":_vm.confirmAction}},[_vm._v(" "+_vm._s(_vm.$t(_vm.confirmButtonText))+" ")]):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }