import Quill from 'quill'

const Parchment = Quill.import('parchment')

export const fontSizes = [
    6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
].map((i) => i + 'pt')

// https://stackoverflow.com/a/43808486/3765413
const Size = Quill.import('attributors/style/size')
Size.whitelist = fontSizes
Quill.register(Size, true)

// TODO : Line Height
// https://github.com/quilljs/quill/issues/197#issuecomment-305109517
export const lineHeightConfig = {
    scope: Parchment.Scope.BLOCK,
    whitelist: [
        '1.0',
        '1.2',
        '1.5',
        '1.6',
        '1.8',
        '2.0',
        '2.4',
        '2.8',
    ],
}
const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig)
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig)
Parchment.register(lineHeightClass)
Parchment.register(lineHeightStyle)


// TODO : CharMapModal

export default {
    theme: 'snow',
    modules: {
        toolbar: {
            container: '#editor-toolbar',
            handlers: {
                // Quill is automatically binding the toolbar to `this` in the
                // methods. We access the editor via the attribute `this.quill`.
                // The TS compiler doesn't know that. Hence, we tell TS to
                // ignore the line. This is quite unfortunate.
                'custom-undo'() {
                    // @ts-ignore
                    this.quill.history.undo()
                },
                'custom-redo'() {
                    // @ts-ignore
                    this.quill.history.redo()
                },
            },
        },
    },
    readOnly: false,
}
