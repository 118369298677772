
















































import {Criterion, Segment, SegmentSelectionEnum} from '@/openapi'
import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class EditFormSegmentMultiple extends Vue {
    @Prop(Object) private readonly segment!: Segment
    @Prop(Number) private readonly index!: number
    @Prop(Boolean) private readonly disableInputs!: boolean
    @Prop({default: null}) private readonly values!: Array<Criterion>

    private get criteria(): Array<Criterion> {
        return this.values
    }

    private set criteria(criteria: Array<Criterion>) {
        this.$emit('input', this.index, criteria)
    }

    private isSegmentMandatory(segment: Segment): boolean {
        return segment.selectionType === SegmentSelectionEnum.MultipleValueMandatory
    }
}
