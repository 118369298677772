// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Outside Rest
 * This is a outside Rest server.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kami-outside@sword-group.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * les informations d\'un utilisateur
 * @export
 * @interface Account
 */
export interface Account {
    /**
     * identifiant du compte
     * @type {string}
     * @memberof Account
     */
    id: string;
    /**
     * nom de l\'utilisateur
     * @type {string}
     * @memberof Account
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Account
     */
    lastName: string;
    /**
     * la date de creation de l\'utilisateur
     * @type {Date}
     * @memberof Account
     */
    creationDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    isAdmin: boolean;
    /**
     * 
     * @type {Array<DocumentRole>}
     * @memberof Account
     */
    roles: Array<DocumentRole>;
    /**
     * 
     * @type {boolean}
     * @memberof Account
     */
    firstLogin: boolean;
}
/**
 * 
 * @export
 * @interface AccountCreation
 */
export interface AccountCreation {
    /**
     * nom de l\'utilisateur
     * @type {string}
     * @memberof AccountCreation
     */
    login: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreation
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreation
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountCreation
     */
    lastName: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCreation
     */
    isAdmin: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountCreation
     */
    firstLogin?: boolean;
    /**
     * 
     * @type {Array<DocumentRole>}
     * @memberof AccountCreation
     */
    roles: Array<DocumentRole>;
}
/**
 * 
 * @export
 * @interface AccountMail
 */
export interface AccountMail {
    /**
     * 
     * @type {string}
     * @memberof AccountMail
     */
    email: string;
}
/**
 * 
 * @export
 * @interface AccountPage
 */
export interface AccountPage {
    /**
     * 
     * @type {number}
     * @memberof AccountPage
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPage
     */
    totalPages: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPage
     */
    last: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountPage
     */
    numberOfElements: number;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPage
     */
    first: boolean;
    /**
     * 
     * @type {number}
     * @memberof AccountPage
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof AccountPage
     */
    number: number;
    /**
     * 
     * @type {Array<Account>}
     * @memberof AccountPage
     */
    items: Array<Account>;
}
/**
 * 
 * @export
 * @interface AccountPatch
 */
export interface AccountPatch {
    /**
     * 
     * @type {string}
     * @memberof AccountPatch
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPatch
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPatch
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountPatch
     */
    lastName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPatch
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPatch
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AccountPatch
     */
    activated?: boolean;
    /**
     * 
     * @type {Array<DocumentRole>}
     * @memberof AccountPatch
     */
    roles?: Array<DocumentRole>;
}
/**
 * une demande d\"autentification
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    password: string;
}
/**
 * la reponse d\'autentification
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * tocken d\'autentification spring
     * @type {string}
     * @memberof AuthResponse
     */
    token: string;
}
/**
 * 
 * @export
 * @interface Conflict
 */
export interface Conflict {
    /**
     * 
     * @type {string}
     * @memberof Conflict
     */
    messageName: string;
    /**
     * 
     * @type {Array<ConflictSegments>}
     * @memberof Conflict
     */
    segments: Array<ConflictSegments>;
}
/**
 * 
 * @export
 * @interface ConflictSegments
 */
export interface ConflictSegments {
    /**
     * 
     * @type {string}
     * @memberof ConflictSegments
     */
    segmentId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConflictSegments
     */
    criteriaIds: Array<string>;
}
/**
 * les informations d\'un critere
 * @export
 * @interface Criterion
 */
export interface Criterion {
    /**
     * identifiant unique du critere
     * @type {string}
     * @memberof Criterion
     */
    id: string;
    /**
     * nom du critere
     * @type {string}
     * @memberof Criterion
     */
    name: string;
    /**
     * ordre d\'afichage du critere
     * @type {number}
     * @memberof Criterion
     */
    order: number;
}
/**
 * les informations d\'un document
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * identifiant unique du document
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * nom du document
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * longueur de la frame
     * @type {number}
     * @memberof Document
     */
    frameWidth: number;
    /**
     * hauteur de la frame
     * @type {number}
     * @memberof Document
     */
    frameHeight: number;
    /**
     * coordonnée x du bord supérieur gauche de la frame
     * @type {number}
     * @memberof Document
     */
    frameX: number;
    /**
     * coordonnée y du bord supérieur gauche de la frame
     * @type {number}
     * @memberof Document
     */
    frameY: number;
    /**
     * identifiant de l\'image
     * @type {string}
     * @memberof Document
     */
    imageId: string;
    /**
     * liste des id fonts du documents
     * @type {Array<string>}
     * @memberof Document
     */
    fontsId: Array<string>;
}
/**
 * 
 * @export
 * @interface DocumentRole
 */
export interface DocumentRole {
    /**
     * 
     * @type {string}
     * @memberof DocumentRole
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentRole
     */
    roleId: string;
}
/**
 * 
 * @export
 * @interface ErrorDetails
 */
export interface ErrorDetails {
    /**
     * 
     * @type {Date}
     * @memberof ErrorDetails
     */
    timestamp?: Date;
    /**
     * 
     * @type {number}
     * @memberof ErrorDetails
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorDetails
     */
    path?: string;
}
/**
 * les informations d\'une font
 * @export
 * @interface Font
 */
export interface Font {
    /**
     * identifiant unique de la font
     * @type {string}
     * @memberof Font
     */
    id: string;
    /**
     * nom de la font
     * @type {string}
     * @memberof Font
     */
    name: string;
    /**
     * taille par défaut de la police
     * @type {number}
     * @memberof Font
     */
    defaultSize: number;
}
/**
 * les informations d\'une image
 * @export
 * @interface Image
 */
export interface Image {
    /**
     * identifiant unique de l\'image
     * @type {string}
     * @memberof Image
     */
    id: string;
    /**
     * contenu de l\'image
     * @type {string}
     * @memberof Image
     */
    content: string;
}
/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    name: string;
    /**
     * 
     * @type {MessageState}
     * @memberof Message
     */
    state?: MessageState;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    milestoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Message
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Message
     */
    criteriaIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    modificationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof Message
     */
    latestExport?: Date;
}
/**
 * 
 * @export
 * @interface MessageCreate
 */
export interface MessageCreate {
    /**
     * 
     * @type {string}
     * @memberof MessageCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MessageCreate
     */
    documentId: string;
}
/**
 * 
 * @export
 * @interface MessageDuplicate
 */
export interface MessageDuplicate {
    /**
     * 
     * @type {string}
     * @memberof MessageDuplicate
     */
    name: string;
    /**
     * 
     * @type {MessageState}
     * @memberof MessageDuplicate
     */
    state: MessageState;
    /**
     * 
     * @type {string}
     * @memberof MessageDuplicate
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDuplicate
     */
    milestoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageDuplicate
     */
    content: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageDuplicate
     */
    isDefault: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageDuplicate
     */
    criteriaIds: Array<string>;
}
/**
 * 
 * @export
 * @interface MessagePatch
 */
export interface MessagePatch {
    /**
     * 
     * @type {string}
     * @memberof MessagePatch
     */
    name?: string;
    /**
     * 
     * @type {MessageState}
     * @memberof MessagePatch
     */
    state?: MessageState;
    /**
     * 
     * @type {string}
     * @memberof MessagePatch
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePatch
     */
    milestoneId?: string;
    /**
     * 
     * @type {string}
     * @memberof MessagePatch
     */
    content?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessagePatch
     */
    isDefault?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessagePatch
     */
    criteriaIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface MessagePatchResponse
 */
export interface MessagePatchResponse {
    /**
     * 
     * @type {MessagePatchResponseStatus}
     * @memberof MessagePatchResponse
     */
    status: MessagePatchResponseStatus;
    /**
     * 
     * @type {Array<Conflict>}
     * @memberof MessagePatchResponse
     */
    conflicts?: Array<Conflict>;
    /**
     * 
     * @type {Message}
     * @memberof MessagePatchResponse
     */
    message: Message;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MessagePatchResponseStatus {
    Ok = 'ok',
    Conflicts = 'conflicts'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageState {
    Draft1 = 'draft1',
    Draft2 = 'draft2',
    Draft3 = 'draft3',
    Validated = 'validated'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageStateFilter {
    Draft = 'draft',
    Validated = 'validated',
    Exported = 'exported'
}

/**
 * 
 * @export
 * @interface MessageSummary
 */
export interface MessageSummary {
    /**
     * 
     * @type {string}
     * @memberof MessageSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSummary
     */
    name: string;
    /**
     * 
     * @type {MessageState}
     * @memberof MessageSummary
     */
    state: MessageState;
    /**
     * 
     * @type {boolean}
     * @memberof MessageSummary
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageSummary
     */
    documentId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSummary
     */
    milestoneId?: string;
    /**
     * 
     * @type {Date}
     * @memberof MessageSummary
     */
    modificationDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof MessageSummary
     */
    latestExport?: Date;
}
/**
 * 
 * @export
 * @interface MessageSummaryPage
 */
export interface MessageSummaryPage {
    /**
     * 
     * @type {number}
     * @memberof MessageSummaryPage
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof MessageSummaryPage
     */
    totalPages: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageSummaryPage
     */
    last: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageSummaryPage
     */
    numberOfElements: number;
    /**
     * 
     * @type {boolean}
     * @memberof MessageSummaryPage
     */
    first: boolean;
    /**
     * 
     * @type {number}
     * @memberof MessageSummaryPage
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof MessageSummaryPage
     */
    number: number;
    /**
     * 
     * @type {Array<MessageSummary>}
     * @memberof MessageSummaryPage
     */
    items: Array<MessageSummary>;
}
/**
 * les informations d\'une campagne
 * @export
 * @interface Milestone
 */
export interface Milestone {
    /**
     * identifiant unique de la campagne
     * @type {string}
     * @memberof Milestone
     */
    id: string;
    /**
     * nom de la campagne
     * @type {string}
     * @memberof Milestone
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Milestone
     */
    documentId: string;
    /**
     * 
     * @type {MilestoneState}
     * @memberof Milestone
     */
    state: MilestoneState;
    /**
     * date de début de la campagne
     * @type {string}
     * @memberof Milestone
     */
    dateStart: string;
    /**
     * date de fin de la campagne
     * @type {string}
     * @memberof Milestone
     */
    dateEnd: string;
    /**
     * date de modification de la campagne
     * @type {Date}
     * @memberof Milestone
     */
    modificationDate: Date;
    /**
     * date du plus récent export de la campagne
     * @type {Date}
     * @memberof Milestone
     */
    latestExport?: Date;
    /**
     * uuid du message par défaut s\'il existe
     * @type {string}
     * @memberof Milestone
     */
    defaultMessageId?: string;
}
/**
 * les informations d\'une campagne à créer
 * @export
 * @interface MilestoneCreate
 */
export interface MilestoneCreate {
    /**
     * nom de la campagne
     * @type {string}
     * @memberof MilestoneCreate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MilestoneCreate
     */
    documentId: string;
    /**
     * date de début de la campagne
     * @type {string}
     * @memberof MilestoneCreate
     */
    dateStart: string;
    /**
     * date de fin de la campagne
     * @type {string}
     * @memberof MilestoneCreate
     */
    dateEnd: string;
}
/**
 * les informations d\'une campagne à créer
 * @export
 * @interface MilestonePatch
 */
export interface MilestonePatch {
    /**
     * nom de la campagne
     * @type {string}
     * @memberof MilestonePatch
     */
    name?: string;
    /**
     * date de début de la campagne
     * @type {string}
     * @memberof MilestonePatch
     */
    dateStart?: string;
    /**
     * date de fin de la campagne
     * @type {string}
     * @memberof MilestonePatch
     */
    dateEnd?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MilestoneState {
    Running = 'running',
    NotStarted = 'notStarted',
    Finished = 'finished'
}

/**
 * 
 * @export
 * @interface Paging
 */
export interface Paging {
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    totalElements: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    totalPages: number;
    /**
     * 
     * @type {boolean}
     * @memberof Paging
     */
    last: boolean;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    numberOfElements: number;
    /**
     * 
     * @type {boolean}
     * @memberof Paging
     */
    first: boolean;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof Paging
     */
    number: number;
}
/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Role
     */
    rights: Array<string>;
}
/**
 * les informations d\'un segment
 * @export
 * @interface Segment
 */
export interface Segment {
    /**
     * identifiant unique du segment
     * @type {string}
     * @memberof Segment
     */
    id: string;
    /**
     * nom du segment
     * @type {string}
     * @memberof Segment
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Segment
     */
    documentId: string;
    /**
     * 
     * @type {SegmentSelectionEnum}
     * @memberof Segment
     */
    selectionType: SegmentSelectionEnum;
    /**
     * ordre d\'afichage du segment
     * @type {number}
     * @memberof Segment
     */
    order: number;
    /**
     * la liste des critere associé
     * @type {Array<Criterion>}
     * @memberof Segment
     */
    criteria: Array<Criterion>;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SegmentSelectionEnum {
    SingleValue = 'singleValue',
    SingleValueMandatory = 'singleValueMandatory',
    MultipleValue = 'multipleValue',
    MultipleValueMandatory = 'multipleValueMandatory'
}

/**
 * les inforations d\'un utilisateur
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * nom de l\'utilisateur
     * @type {string}
     * @memberof UserInfo
     */
    name: string;
    /**
     * nom du role
     * @type {string}
     * @memberof UserInfo
     */
    role: string;
    /**
     * les role associé a l\'utilisateur
     * @type {Array<string>}
     * @memberof UserInfo
     */
    roles?: Array<string>;
}
/**
 * contient la requête pour activer un compte ainsi que son mot de passe
 * @export
 * @interface Validate
 */
export interface Validate {
    /**
     * 
     * @type {string}
     * @memberof Validate
     */
    newPassword: string;
}

/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ajout compte utilisateurs
         * @param {AccountCreation} accountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountCreation: AccountCreation, options: any = {}): RequestArgs {
            // verify required parameter 'accountCreation' is not null or undefined
            if (accountCreation === null || accountCreation === undefined) {
                throw new RequiredError('accountCreation','Required parameter accountCreation was null or undefined when calling create.');
            }
            const localVarPath = `/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountCreation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountCreation !== undefined ? accountCreation : {}) : (accountCreation || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * récupération d\'un compte par son token d\'activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByActivationToken(options: any = {}): RequestArgs {
            const localVarPath = `/validate-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * liste des compte utilisateurs
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(sort?: Array<string>, page?: number, size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/accounts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options: any = {}): RequestArgs {
            const localVarPath = `/roles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options: any = {}): RequestArgs {
            const localVarPath = `/me`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AccountMail} accountMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(accountMail: AccountMail, options: any = {}): RequestArgs {
            // verify required parameter 'accountMail' is not null or undefined
            if (accountMail === null || accountMail === undefined) {
                throw new RequiredError('accountMail','Required parameter accountMail was null or undefined when calling resetPassword.');
            }
            const localVarPath = `/reset-password`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountMail" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountMail !== undefined ? accountMail : {}) : (accountMail || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * activation d\'un utilisateur
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivationMail(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling sendActivationMail.');
            }
            const localVarPath = `/accounts/activation/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {AccountPatch} accountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, accountPatch: AccountPatch, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'accountPatch' is not null or undefined
            if (accountPatch === null || accountPatch === undefined) {
                throw new RequiredError('accountPatch','Required parameter accountPatch was null or undefined when calling update.');
            }
            const localVarPath = `/accounts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AccountPatch" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(accountPatch !== undefined ? accountPatch : {}) : (accountPatch || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * activation dun compte par token
         * @param {Validate} validate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(validate: Validate, options: any = {}): RequestArgs {
            // verify required parameter 'validate' is not null or undefined
            if (validate === null || validate === undefined) {
                throw new RequiredError('validate','Required parameter validate was null or undefined when calling validate.');
            }
            const localVarPath = `/validate-account`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"Validate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(validate !== undefined ? validate : {}) : (validate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ajout compte utilisateurs
         * @param {AccountCreation} accountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountCreation: AccountCreation, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).create(accountCreation, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * récupération d\'un compte par son token d\'activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByActivationToken(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).getAccountByActivationToken(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * liste des compte utilisateurs
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(sort?: Array<string>, page?: number, size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountPage> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).list(sort, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Role>> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).listRoles(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).me(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {AccountMail} accountMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(accountMail: AccountMail, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).resetPassword(accountMail, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * activation d\'un utilisateur
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivationMail(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).sendActivationMail(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {AccountPatch} accountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, accountPatch: AccountPatch, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).update(id, accountPatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * activation dun compte par token
         * @param {Validate} validate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(validate: Validate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account> {
            const localVarAxiosArgs = AccountsApiAxiosParamCreator(configuration).validate(validate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ajout compte utilisateurs
         * @param {AccountCreation} accountCreation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(accountCreation: AccountCreation, options?: any) {
            return AccountsApiFp(configuration).create(accountCreation, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return AccountsApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * récupération d\'un compte par son token d\'activation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountByActivationToken(options?: any) {
            return AccountsApiFp(configuration).getAccountByActivationToken(options)(axios, basePath);
        },
        /**
         * liste des compte utilisateurs
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(sort?: Array<string>, page?: number, size?: number, options?: any) {
            return AccountsApiFp(configuration).list(sort, page, size, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRoles(options?: any) {
            return AccountsApiFp(configuration).listRoles(options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any) {
            return AccountsApiFp(configuration).me(options)(axios, basePath);
        },
        /**
         * 
         * @param {AccountMail} accountMail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(accountMail: AccountMail, options?: any) {
            return AccountsApiFp(configuration).resetPassword(accountMail, options)(axios, basePath);
        },
        /**
         * activation d\'un utilisateur
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendActivationMail(id: string, options?: any) {
            return AccountsApiFp(configuration).sendActivationMail(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {AccountPatch} accountPatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, accountPatch: AccountPatch, options?: any) {
            return AccountsApiFp(configuration).update(id, accountPatch, options)(axios, basePath);
        },
        /**
         * activation dun compte par token
         * @param {Validate} validate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validate(validate: Validate, options?: any) {
            return AccountsApiFp(configuration).validate(validate, options)(axios, basePath);
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * ajout compte utilisateurs
     * @param {AccountCreation} accountCreation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public create(accountCreation: AccountCreation, options?: any) {
        return AccountsApiFp(this.configuration).create(accountCreation, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public get(id: string, options?: any) {
        return AccountsApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * récupération d\'un compte par son token d\'activation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccountByActivationToken(options?: any) {
        return AccountsApiFp(this.configuration).getAccountByActivationToken(options)(this.axios, this.basePath);
    }

    /**
     * liste des compte utilisateurs
     * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public list(sort?: Array<string>, page?: number, size?: number, options?: any) {
        return AccountsApiFp(this.configuration).list(sort, page, size, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public listRoles(options?: any) {
        return AccountsApiFp(this.configuration).listRoles(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public me(options?: any) {
        return AccountsApiFp(this.configuration).me(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {AccountMail} accountMail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public resetPassword(accountMail: AccountMail, options?: any) {
        return AccountsApiFp(this.configuration).resetPassword(accountMail, options)(this.axios, this.basePath);
    }

    /**
     * activation d\'un utilisateur
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public sendActivationMail(id: string, options?: any) {
        return AccountsApiFp(this.configuration).sendActivationMail(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {AccountPatch} accountPatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public update(id: string, accountPatch: AccountPatch, options?: any) {
        return AccountsApiFp(this.configuration).update(id, accountPatch, options)(this.axios, this.basePath);
    }

    /**
     * activation dun compte par token
     * @param {Validate} validate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public validate(validate: Validate, options?: any) {
        return AccountsApiFp(this.configuration).validate(validate, options)(this.axios, this.basePath);
    }

}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * recuperation info utilisateur
         * @param {string} token le token de connection de l\&#39;utilisateur
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUserInfo(token: string, options: any = {}): RequestArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling loadUserInfo.');
            }
            const localVarPath = `/auth/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * demande d\'autentification
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuth(authRequest: AuthRequest, options: any = {}): RequestArgs {
            // verify required parameter 'authRequest' is not null or undefined
            if (authRequest === null || authRequest === undefined) {
                throw new RequiredError('authRequest','Required parameter authRequest was null or undefined when calling postAuth.');
            }
            const localVarPath = `/auth`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"AuthRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(authRequest !== undefined ? authRequest : {}) : (authRequest || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * recuperation info utilisateur
         * @param {string} token le token de connection de l\&#39;utilisateur
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUserInfo(token: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).loadUserInfo(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * demande d\'autentification
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuth(authRequest: AuthRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse> {
            const localVarAxiosArgs = AuthApiAxiosParamCreator(configuration).postAuth(authRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * recuperation info utilisateur
         * @param {string} token le token de connection de l\&#39;utilisateur
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadUserInfo(token: string, options?: any) {
            return AuthApiFp(configuration).loadUserInfo(token, options)(axios, basePath);
        },
        /**
         * demande d\'autentification
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuth(authRequest: AuthRequest, options?: any) {
            return AuthApiFp(configuration).postAuth(authRequest, options)(axios, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * recuperation info utilisateur
     * @param {string} token le token de connection de l\&#39;utilisateur
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loadUserInfo(token: string, options?: any) {
        return AuthApiFp(this.configuration).loadUserInfo(token, options)(this.axios, this.basePath);
    }

    /**
     * demande d\'autentification
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public postAuth(authRequest: AuthRequest, options?: any) {
        return AuthApiFp(this.configuration).postAuth(authRequest, options)(this.axios, this.basePath);
    }

}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * liste des documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(options: any = {}): RequestArgs {
            const localVarPath = `/documents`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * liste des documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Document>> {
            const localVarAxiosArgs = DocumentsApiAxiosParamCreator(configuration).listDocuments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * liste des documents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDocuments(options?: any) {
            return DocumentsApiFp(configuration).listDocuments(options)(axios, basePath);
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * liste des documents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public listDocuments(options?: any) {
        return DocumentsApiFp(this.configuration).listDocuments(options)(this.axios, this.basePath);
    }

}


/**
 * ExportApi - axios parameter creator
 * @export
 */
export const ExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} ids ids parameter example (ids&#x3D;id1,id2,id3).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipExport(ids: Array<string>, options: any = {}): RequestArgs {
            // verify required parameter 'ids' is not null or undefined
            if (ids === null || ids === undefined) {
                throw new RequiredError('ids','Required parameter ids was null or undefined when calling zipExport.');
            }
            const localVarPath = `/export/{id}`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (ids) {
                localVarQueryParameter['ids'] = ids.join(COLLECTION_FORMATS.csv);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExportApi - functional programming interface
 * @export
 */
export const ExportApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Array<string>} ids ids parameter example (ids&#x3D;id1,id2,id3).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipExport(ids: Array<string>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
            const localVarAxiosArgs = ExportApiAxiosParamCreator(configuration).zipExport(ids, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ExportApi - factory interface
 * @export
 */
export const ExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {Array<string>} ids ids parameter example (ids&#x3D;id1,id2,id3).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        zipExport(ids: Array<string>, options?: any) {
            return ExportApiFp(configuration).zipExport(ids, options)(axios, basePath);
        },
    };
};

/**
 * ExportApi - object-oriented interface
 * @export
 * @class ExportApi
 * @extends {BaseAPI}
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @param {Array<string>} ids ids parameter example (ids&#x3D;id1,id2,id3).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExportApi
     */
    public zipExport(ids: Array<string>, options?: any) {
        return ExportApiFp(this.configuration).zipExport(ids, options)(this.axios, this.basePath);
    }

}


/**
 * FontsApi - axios parameter creator
 * @export
 */
export const FontsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/fonts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options: any = {}): RequestArgs {
            const localVarPath = `/fonts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FontsApi - functional programming interface
 * @export
 */
export const FontsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Font> {
            const localVarAxiosArgs = FontsApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Font>> {
            const localVarAxiosArgs = FontsApiAxiosParamCreator(configuration).list(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FontsApi - factory interface
 * @export
 */
export const FontsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return FontsApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any) {
            return FontsApiFp(configuration).list(options)(axios, basePath);
        },
    };
};

/**
 * FontsApi - object-oriented interface
 * @export
 * @class FontsApi
 * @extends {BaseAPI}
 */
export class FontsApi extends BaseAPI {
    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FontsApi
     */
    public get(id: string, options?: any) {
        return FontsApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FontsApi
     */
    public list(options?: any) {
        return FontsApiFp(this.configuration).list(options)(this.axios, this.basePath);
    }

}


/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/images/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options: any = {}): RequestArgs {
            const localVarPath = `/images`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Image> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Image>> {
            const localVarAxiosArgs = ImagesApiAxiosParamCreator(configuration).list(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return ImagesApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any) {
            return ImagesApiFp(configuration).list(options)(axios, basePath);
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public get(id: string, options?: any) {
        return ImagesApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public list(options?: any) {
        return ImagesApiFp(this.configuration).list(options)(this.axios, this.basePath);
    }

}


/**
 * MessagesApi - axios parameter creator
 * @export
 */
export const MessagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(messageCreate: MessageCreate, options: any = {}): RequestArgs {
            // verify required parameter 'messageCreate' is not null or undefined
            if (messageCreate === null || messageCreate === undefined) {
                throw new RequiredError('messageCreate','Required parameter messageCreate was null or undefined when calling create.');
            }
            const localVarPath = `/messages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MessageCreate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(messageCreate !== undefined ? messageCreate : {}) : (messageCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MessageDuplicate} messageDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(messageDuplicate: MessageDuplicate, options: any = {}): RequestArgs {
            // verify required parameter 'messageDuplicate' is not null or undefined
            if (messageDuplicate === null || messageDuplicate === undefined) {
                throw new RequiredError('messageDuplicate','Required parameter messageDuplicate was null or undefined when calling duplicate.');
            }
            const localVarPath = `/duplicate-messages`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MessageDuplicate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(messageDuplicate !== undefined ? messageDuplicate : {}) : (messageDuplicate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remove.');
            }
            const localVarPath = `/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MessagePatch} messagePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, messagePatch: MessagePatch, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'messagePatch' is not null or undefined
            if (messagePatch === null || messagePatch === undefined) {
                throw new RequiredError('messagePatch','Required parameter messagePatch was null or undefined when calling update.');
            }
            const localVarPath = `/messages/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MessagePatch" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(messagePatch !== undefined ? messagePatch : {}) : (messagePatch || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(messageCreate: MessageCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message> {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).create(messageCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MessageDuplicate} messageDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(messageDuplicate: MessageDuplicate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePatchResponse> {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).duplicate(messageDuplicate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Message> {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).remove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MessagePatch} messagePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, messagePatch: MessagePatch, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagePatchResponse> {
            const localVarAxiosArgs = MessagesApiAxiosParamCreator(configuration).update(id, messagePatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MessageCreate} messageCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(messageCreate: MessageCreate, options?: any) {
            return MessagesApiFp(configuration).create(messageCreate, options)(axios, basePath);
        },
        /**
         * 
         * @param {MessageDuplicate} messageDuplicate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicate(messageDuplicate: MessageDuplicate, options?: any) {
            return MessagesApiFp(configuration).duplicate(messageDuplicate, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return MessagesApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any) {
            return MessagesApiFp(configuration).remove(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MessagePatch} messagePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, messagePatch: MessagePatch, options?: any) {
            return MessagesApiFp(configuration).update(id, messagePatch, options)(axios, basePath);
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {MessageCreate} messageCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public create(messageCreate: MessageCreate, options?: any) {
        return MessagesApiFp(this.configuration).create(messageCreate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {MessageDuplicate} messageDuplicate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public duplicate(messageDuplicate: MessageDuplicate, options?: any) {
        return MessagesApiFp(this.configuration).duplicate(messageDuplicate, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public get(id: string, options?: any) {
        return MessagesApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public remove(id: string, options?: any) {
        return MessagesApiFp(this.configuration).remove(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {MessagePatch} messagePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public update(id: string, messagePatch: MessagePatch, options?: any) {
        return MessagesApiFp(this.configuration).update(id, messagePatch, options)(this.axios, this.basePath);
    }

}


/**
 * MilestonesApi - axios parameter creator
 * @export
 */
export const MilestonesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * création d\'une campgne
         * @param {MilestoneCreate} milestoneCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(milestoneCreate: MilestoneCreate, options: any = {}): RequestArgs {
            // verify required parameter 'milestoneCreate' is not null or undefined
            if (milestoneCreate === null || milestoneCreate === undefined) {
                throw new RequiredError('milestoneCreate','Required parameter milestoneCreate was null or undefined when calling create.');
            }
            const localVarPath = `/milestones`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MilestoneCreate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(milestoneCreate !== undefined ? milestoneCreate : {}) : (milestoneCreate || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * liste des campagnes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options: any = {}): RequestArgs {
            const localVarPath = `/milestones`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling remove.');
            }
            const localVarPath = `/milestones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MilestonePatch} milestonePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, milestonePatch: MilestonePatch, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'milestonePatch' is not null or undefined
            if (milestonePatch === null || milestonePatch === undefined) {
                throw new RequiredError('milestonePatch','Required parameter milestonePatch was null or undefined when calling update.');
            }
            const localVarPath = `/milestones/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"MilestonePatch" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(milestonePatch !== undefined ? milestonePatch : {}) : (milestonePatch || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MilestonesApi - functional programming interface
 * @export
 */
export const MilestonesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * création d\'une campgne
         * @param {MilestoneCreate} milestoneCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(milestoneCreate: MilestoneCreate, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Milestone> {
            const localVarAxiosArgs = MilestonesApiAxiosParamCreator(configuration).create(milestoneCreate, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * liste des campagnes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Milestone>> {
            const localVarAxiosArgs = MilestonesApiAxiosParamCreator(configuration).list(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
            const localVarAxiosArgs = MilestonesApiAxiosParamCreator(configuration).remove(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MilestonePatch} milestonePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, milestonePatch: MilestonePatch, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Milestone> {
            const localVarAxiosArgs = MilestonesApiAxiosParamCreator(configuration).update(id, milestonePatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MilestonesApi - factory interface
 * @export
 */
export const MilestonesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * création d\'une campgne
         * @param {MilestoneCreate} milestoneCreate 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(milestoneCreate: MilestoneCreate, options?: any) {
            return MilestonesApiFp(configuration).create(milestoneCreate, options)(axios, basePath);
        },
        /**
         * liste des campagnes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(options?: any) {
            return MilestonesApiFp(configuration).list(options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        remove(id: string, options?: any) {
            return MilestonesApiFp(configuration).remove(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {MilestonePatch} milestonePatch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, milestonePatch: MilestonePatch, options?: any) {
            return MilestonesApiFp(configuration).update(id, milestonePatch, options)(axios, basePath);
        },
    };
};

/**
 * MilestonesApi - object-oriented interface
 * @export
 * @class MilestonesApi
 * @extends {BaseAPI}
 */
export class MilestonesApi extends BaseAPI {
    /**
     * création d\'une campgne
     * @param {MilestoneCreate} milestoneCreate 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestonesApi
     */
    public create(milestoneCreate: MilestoneCreate, options?: any) {
        return MilestonesApiFp(this.configuration).create(milestoneCreate, options)(this.axios, this.basePath);
    }

    /**
     * liste des campagnes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestonesApi
     */
    public list(options?: any) {
        return MilestonesApiFp(this.configuration).list(options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestonesApi
     */
    public remove(id: string, options?: any) {
        return MilestonesApiFp(this.configuration).remove(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {MilestonePatch} milestonePatch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MilestonesApi
     */
    public update(id: string, milestonePatch: MilestonePatch, options?: any) {
        return MilestonesApiFp(this.configuration).update(id, milestonePatch, options)(this.axios, this.basePath);
    }

}


/**
 * SegmentsApi - axios parameter creator
 * @export
 */
export const SegmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * liste des segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSegments(options: any = {}): RequestArgs {
            const localVarPath = `/segments`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SegmentsApi - functional programming interface
 * @export
 */
export const SegmentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * liste des segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSegments(options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Segment>> {
            const localVarAxiosArgs = SegmentsApiAxiosParamCreator(configuration).listSegments(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SegmentsApi - factory interface
 * @export
 */
export const SegmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * liste des segment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSegments(options?: any) {
            return SegmentsApiFp(configuration).listSegments(options)(axios, basePath);
        },
    };
};

/**
 * SegmentsApi - object-oriented interface
 * @export
 * @class SegmentsApi
 * @extends {BaseAPI}
 */
export class SegmentsApi extends BaseAPI {
    /**
     * liste des segment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SegmentsApi
     */
    public listSegments(options?: any) {
        return SegmentsApiFp(this.configuration).listSegments(options)(this.axios, this.basePath);
    }

}


/**
 * SummariesApi - axios parameter creator
 * @export
 */
export const SummariesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/summaries/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [documentIds] document Ids parameter example (documentIds&#x3D;id1,id2,id3).
         * @param {Array<MilestoneState>} [milestoneStates] milestone Ids parameter example (milestoneStates&#x3D;running,finished).
         * @param {Array<string>} [milestoneIds] milestone Ids parameter example (milestoneIds&#x3D;id1,id2,id3).
         * @param {Array<MessageStateFilter>} [states] messate states parameter example (states&#x3D;draft1,draft2).
         * @param {string} [dateStart] dateStart parameter.
         * @param {string} [dateEnd] dateEnd parameter.
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(documentIds?: Array<string>, milestoneStates?: Array<MilestoneState>, milestoneIds?: Array<string>, states?: Array<MessageStateFilter>, dateStart?: string, dateEnd?: string, sort?: Array<string>, page?: number, size?: number, options: any = {}): RequestArgs {
            const localVarPath = `/summaries`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === 'function'
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }

            if (documentIds) {
                localVarQueryParameter['documentIds'] = documentIds.join(COLLECTION_FORMATS.csv);
            }

            if (milestoneStates) {
                localVarQueryParameter['milestoneStates'] = milestoneStates.join(COLLECTION_FORMATS.csv);
            }

            if (milestoneIds) {
                localVarQueryParameter['milestoneIds'] = milestoneIds.join(COLLECTION_FORMATS.csv);
            }

            if (states) {
                localVarQueryParameter['states'] = states.join(COLLECTION_FORMATS.csv);
            }

            if (dateStart !== undefined) {
                localVarQueryParameter['dateStart'] = (dateStart as any instanceof Date) ?
                    (dateStart as any).toISOString().substr(0,10) :
                    dateStart;
            }

            if (dateEnd !== undefined) {
                localVarQueryParameter['dateEnd'] = (dateEnd as any instanceof Date) ?
                    (dateEnd as any).toISOString().substr(0,10) :
                    dateEnd;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SummariesApi - functional programming interface
 * @export
 */
export const SummariesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageSummary> {
            const localVarAxiosArgs = SummariesApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [documentIds] document Ids parameter example (documentIds&#x3D;id1,id2,id3).
         * @param {Array<MilestoneState>} [milestoneStates] milestone Ids parameter example (milestoneStates&#x3D;running,finished).
         * @param {Array<string>} [milestoneIds] milestone Ids parameter example (milestoneIds&#x3D;id1,id2,id3).
         * @param {Array<MessageStateFilter>} [states] messate states parameter example (states&#x3D;draft1,draft2).
         * @param {string} [dateStart] dateStart parameter.
         * @param {string} [dateEnd] dateEnd parameter.
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(documentIds?: Array<string>, milestoneStates?: Array<MilestoneState>, milestoneIds?: Array<string>, states?: Array<MessageStateFilter>, dateStart?: string, dateEnd?: string, sort?: Array<string>, page?: number, size?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageSummaryPage> {
            const localVarAxiosArgs = SummariesApiAxiosParamCreator(configuration).list(documentIds, milestoneStates, milestoneIds, states, dateStart, dateEnd, sort, page, size, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SummariesApi - factory interface
 * @export
 */
export const SummariesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id l\&#39;id de l\&#39;objet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return SummariesApiFp(configuration).get(id, options)(axios, basePath);
        },
        /**
         * 
         * @param {Array<string>} [documentIds] document Ids parameter example (documentIds&#x3D;id1,id2,id3).
         * @param {Array<MilestoneState>} [milestoneStates] milestone Ids parameter example (milestoneStates&#x3D;running,finished).
         * @param {Array<string>} [milestoneIds] milestone Ids parameter example (milestoneIds&#x3D;id1,id2,id3).
         * @param {Array<MessageStateFilter>} [states] messate states parameter example (states&#x3D;draft1,draft2).
         * @param {string} [dateStart] dateStart parameter.
         * @param {string} [dateEnd] dateEnd parameter.
         * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
         * @param {number} [page] Results page you want to retrieve (0..N)
         * @param {number} [size] Number of records per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(documentIds?: Array<string>, milestoneStates?: Array<MilestoneState>, milestoneIds?: Array<string>, states?: Array<MessageStateFilter>, dateStart?: string, dateEnd?: string, sort?: Array<string>, page?: number, size?: number, options?: any) {
            return SummariesApiFp(configuration).list(documentIds, milestoneStates, milestoneIds, states, dateStart, dateEnd, sort, page, size, options)(axios, basePath);
        },
    };
};

/**
 * SummariesApi - object-oriented interface
 * @export
 * @class SummariesApi
 * @extends {BaseAPI}
 */
export class SummariesApi extends BaseAPI {
    /**
     * 
     * @param {string} id l\&#39;id de l\&#39;objet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummariesApi
     */
    public get(id: string, options?: any) {
        return SummariesApiFp(this.configuration).get(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @param {Array<string>} [documentIds] document Ids parameter example (documentIds&#x3D;id1,id2,id3).
     * @param {Array<MilestoneState>} [milestoneStates] milestone Ids parameter example (milestoneStates&#x3D;running,finished).
     * @param {Array<string>} [milestoneIds] milestone Ids parameter example (milestoneIds&#x3D;id1,id2,id3).
     * @param {Array<MessageStateFilter>} [states] messate states parameter example (states&#x3D;draft1,draft2).
     * @param {string} [dateStart] dateStart parameter.
     * @param {string} [dateEnd] dateEnd parameter.
     * @param {Array<string>} [sort] sort parameter exemple (sort&#x3D;key1:asc,key2:desc,key3).
     * @param {number} [page] Results page you want to retrieve (0..N)
     * @param {number} [size] Number of records per page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SummariesApi
     */
    public list(documentIds?: Array<string>, milestoneStates?: Array<MilestoneState>, milestoneIds?: Array<string>, states?: Array<MessageStateFilter>, dateStart?: string, dateEnd?: string, sort?: Array<string>, page?: number, size?: number, options?: any) {
        return SummariesApiFp(this.configuration).list(documentIds, milestoneStates, milestoneIds, states, dateStart, dateEnd, sort, page, size, options)(this.axios, this.basePath);
    }

}


