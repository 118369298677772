

































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class EditFormRadio<T> extends Vue {
    @Prop(String) private readonly itemText!: string
    @Prop(Object) private readonly value!: T
    @Prop(Array) private readonly items!: Array<T>

    get selected() {
        return this.value
    }

    set selected(selected: T) {
        this.$emit('input', selected)
    }
}
