






































































import {AccountMail} from '@/openapi/api'
import {fetchData, resetStore} from '@/store/actions/globalActions'
import {AxiosError} from 'axios'
import {Component, Vue} from 'vue-property-decorator'

@Component
export default class ForgottenPassword extends Vue {
    private emailNotFound: boolean = false
    private email: string = ''
    private emailSent: boolean = false
    private accountNotActivated: boolean = false

    private async handleValidate() {
        const accountMail: AccountMail = {email: this.email}
        await this.$modules.accounts.resetPassword(accountMail)
            .then(() => {
                this.emailNotFound = false
                this.emailSent = true
                this.accountNotActivated = false
            })
            .catch((error: AxiosError) => {
                this.emailNotFound = (error!.response!.status === 400)
                this.accountNotActivated = (error!.response!.status === 409)
            })
    }
}
