import {FontsApi, Font} from '@/openapi/api'
import {AxiosResponse} from 'axios'
import Vue from 'vue'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'
// TODO : Rename the store/types `Foo` in `FooEntity`.

const fontsApi: FontsApi = new FontsApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class FontsModules extends VuexModule {
    private fonts: Map<string, Font> = new Map()
    private status: string = ''

    @Action
    public async loadFonts() {
        this.request()
        return await fontsApi.list().then((response) => {
            this.set(response.data)
            this.success()
        }).catch((err: Error) => {
            this.error()
        })
    }

    public getFontById(fontId: string) {
        return this.fonts.get(fontId)
    }

    @Mutation
    public reset() {
        this.fonts = new Map()
        this.status = ''
    }

    @Mutation
    public request() {
        this.status = 'loading'
    }

    @Mutation
    public success() {
        this.status = 'success'
    }

    @Mutation
    public error() {
        this.status = 'error'
    }
    @Mutation
    private set(fonts: Array<Font>) {
        this.fonts = new Map(fonts.map((font) => [font.id, font]))
    }

}
