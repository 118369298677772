







































import Dialog from '@/ui/atoms/Dialog.vue'
import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component({
    components: {Dialog},
})
export default class EditFormMessageTitle extends Vue {

    private isContained = true
    private isUniqueTitle = true

    @Prop(Boolean) private readonly isUnique!: boolean
    @Prop(String) private readonly label!: string
    @Prop(String) private readonly value!: string
    @Prop(String) private readonly placeholder!: string
    @Prop({default: 'text'}) private readonly type!: string
    @Prop({default: ''}) private readonly cssClass!: string

    private get title() {
        return this.value
    }

    private set title(title) {
        this.$emit('input', title)
    }

    @Watch('isUnique')
    private updateUniqueness() {
        this.isUniqueTitle = this.isUnique
    }

    @Watch('value')
    private updateField() {
        this.isContained = this.value.length < 35
        this.isUniqueTitle = true
        this.$emit('unique', this.isUniqueTitle)
        this.$emit('contained', this.isContained)
    }

    private mounted() {
        this.isContained = this.value.length < 35
        this.isUniqueTitle = this.isUnique
        this.$emit('contained', this.isContained)
    }

}
