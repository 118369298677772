



































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'

@Component({})
export default class EditFormCheckbox<T> extends Vue {

    @Prop(Array) private readonly items!: Array<T>
    @Prop(Array) private readonly values!: Array<T>

    private selectedItems: Array<T> = this.values

    private emit(item: T) {
        this.$emit('input', this.selectedItems)
    }

    @Watch('values')
    private resetCheckboxes() {
        this.selectedItems = this.values
    }
}
