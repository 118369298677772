import {AccountPatch, MessageState, MessageStateFilter, MilestoneState} from '@/openapi'
import {Route} from 'vue-router'
import Next from '@/router/Next'

/**
 * The MessageDraft interface is used to track all the information of the draft
 * during the creation and edition of a message.
 */
export interface MessageDraft {
    name?: string
    documentId?: string
    content?: string,
    milestoneId?: string,
    criteriaIds?: Array<string>,
    isDefault?: boolean,
    state?: MessageState
}

/**
 * une demande de patch pour un accont
 */

export interface AccountPatchRequest {
    id: string,
    patch: AccountPatch
}

/**
 * interface to call save dialog
 */

export interface SaveCancelDialogInterface {
    popUp: (to: Route, next: Next) => void
    forceRedirection: () => void
    interruptRedirection: () => void
}


export enum SaveCancelType {
     Save,
     Cancel,
}

/**
 * pour aficher joliement les filtre sur l'etat des milestone
 */
export interface FormatedMilestoneState {
    id: number
    name: string
    state: MilestoneState
}


/**
 * pour afficher joliement les filtre sur l'etat des message
 */
export interface FormatedMessageState {
    id: number
    name: string
    state: MessageStateFilter
}
