








































































































































import {Document, Milestone} from '@/openapi/api'
import {tableFooter} from '@/plugins/i18n'
import {exportCampaigns} from '@/store/actions/exportActions'
import {MANAGE_EXPORTS} from '@/store/Right'
import EditFormCheckbox from '@/ui/atoms/edit-form/EditFormCheckbox.vue'
import EditFormTitleStatic from '@/ui/atoms/edit-form/EditFormTitleStatic.vue'
import {Component, Vue} from 'vue-property-decorator'
import doc = Mocha.reporters.doc

interface FormattedMilestone {
    id: string
    name: string
    document: Document
    dateStart: Date
    dateEnd: Date,
    modificationDate: Date,
    latestExport: Date | undefined
}

@Component({
    components: {EditFormTitleStatic, EditFormCheckbox},
})
export default class ExportCampaigns extends Vue {

    private static compareItems(a: FormattedMilestone, b: FormattedMilestone, index: string, isDesc: boolean) {
        const order = (!isDesc ? 1 : -1)
        switch (index) {
            case 'name':
                return order * a.name.localeCompare(b.name)
            case 'document':
                return order * a.document.name.localeCompare(b.document.name)
            case 'start':
                return order * b.dateStart.valueOf() - a.dateStart.valueOf()
            case 'end':
                return order * b.dateEnd.valueOf() - a.dateEnd.valueOf()
            default:
                return 0
        }
    }

    private displayExportDialog = false
    private selectedDocuments: Array<Document> = []
    private campaignDates: Array<string> = []
    private filteringDocuments: Array<Document> = []
    private campaignMonth: Date | null = null
    private currentDocumentFilter: Array<Document> = []
    private currentDateFilter: Date | null = null
    private filterActivated: boolean = false
    private sortBy = []
    private sortDesc = []

    private get headers() {
        return [
            {text: '', align: 'left', value: 'production', sortable: false},
            {text: this.$t('milestone.list.name'), align: 'left', value: 'name'},
            {text: this.$t('milestone.list.document'), align: 'left', value: 'document'},
            {text: this.$t('milestone.list.start_date'), align: 'left', value: 'start'},
            {text: this.$t('milestone.list.end_date'), align: 'left', value: 'end'},
            {text: '', align: 'right', value: 'info', sortable: false, width: '20px'},
            {text: '', align: 'left', value: 'actions', sortable: false},
        ]
    }

    private get milestones(): Array<FormattedMilestone> {
        return this.$modules.milestones.all
            .filter((milestone) => this.document(milestone.documentId) !== undefined)
            .filter((milestone) => this.filterMilestoneByDocument(milestone) || !this.filterActivated)
            .filter((milestone) => this.filterMilestoneByMonth(milestone) || !this.filterActivated)
            .map((milestone) => {
                return {
                    id: milestone.id,
                    name: milestone.name,
                    document: this.document(milestone.documentId)!,
                    dateStart: new Date(milestone.dateStart),
                    dateEnd: new Date(milestone.dateEnd),
                    modificationDate: milestone.modificationDate,
                    latestExport: milestone.latestExport,
                    defaultMessageId: milestone.defaultMessageId,
                }
            })
    }

    private filterMilestoneByDocument(milestone: Milestone) {

        const docFilter = this.document(milestone.documentId)
        if (this.currentDocumentFilter.length >= 1) {
            return docFilter !== undefined && this.currentDocumentFilter.indexOf(docFilter) !== -1
        } else {
            return true
        }
    }

    private filterMilestoneByMonth(milestone: Milestone) {

        if (this.currentDateFilter !== null) {
            const y = this.currentDateFilter.getFullYear()
            const m = this.currentDateFilter.getMonth()
            const firstDay = new Date(y, m, 1, 0, 0, 0, 0).getTime()
            const lastDay = new Date(y, m + 1, 0, 23, 59, 59, 999).getTime()

            const campMinDate = new Date(milestone.dateStart + ' 00:00:00.000').getTime()
            const campMaxDate = new Date(milestone.dateEnd + ' 23:59:59.999').getTime()

            return ((firstDay <= campMinDate && campMinDate <= lastDay) ||
                (firstDay <= campMaxDate && campMaxDate <= lastDay)) ||
                (campMinDate <= firstDay && lastDay <= campMaxDate)
        } else {
            return true
        }

    }

    private get documents(): Array<Document> {
        return this.$modules.documents.all.filter((document) => {
            return this.hasRightToManageExports(document)
        })
    }

    private get document(): (documentId: string) => Document | undefined {
        return (documentId: string) => {
            return this.$modules.documents.getById(documentId)
        }
    }


    private customSort(items: Array<FormattedMilestone>, indexes: Array<string>, isDescs: Array<boolean>) {
        return items.sort((a: FormattedMilestone, b: FormattedMilestone) => {
            for (let it = 0; it < indexes.length; ++it) {
                const compareResult = ExportCampaigns.compareItems(a, b, indexes[it], isDescs[it])
                if (compareResult !== 0) {
                    return compareResult
                }
            }
            // By default order by dateStart
            return ExportCampaigns.compareItems(a, b, 'start', true)
        })
    }

    private refreshFilter() {
        this.currentDocumentFilter = []
        this.currentDateFilter = null
        this.campaignMonth = null
        this.filteringDocuments = []
        this.filterActivated = false
    }

    private toggleFilter() {

        this.currentDocumentFilter = this.filteringDocuments
        this.currentDateFilter = this.campaignMonth

        this.filterActivated = true
        this.$modules.milestones.loadMilestones()
    }

    private hasRightToManageExports(document: Document) {
        return this.$modules.accounts.meHasRightOnDocument(document, MANAGE_EXPORTS)
    }

    private exportDocuments() {
        const milestoneIds: Array<string> = []

        if (this.campaignDates.length === 1) {
            this.campaignDates.push(this.campaignDates[0])
        }
        const minDate = new Date(this.campaignDates[0] + ' 00:00:00.000')
        const maxDate = new Date(this.campaignDates[1] + ' 23:59:59.999')


        for (const document of this.selectedDocuments) {
            this.$modules.milestones.milestonesWithDocumentId(document.id)
                .filter((camp) => {
                    const campMinDate = new Date(camp.dateStart + ' 00:00:00.000')
                    const campMaxDate = new Date(camp.dateEnd + ' 23:59:59.999')
                    // At least one date of the campaign is in between the selected date range
                    return ((minDate <= campMinDate && campMinDate <= maxDate) ||
                        (minDate <= campMaxDate && campMaxDate <= maxDate)) ||
                        // or both of the dates surround the select range
                        (campMinDate <= minDate && maxDate <= campMaxDate)
                })
                .forEach((milestone) => {
                    milestoneIds[milestoneIds.length] = milestone.id
                })
        }

        if (milestoneIds.length > 0) {
            this.exportMilestones(milestoneIds)
        }
    }


    private exportMilestones(milestoneIds: Array<string>) {
        exportCampaigns(this.$modules, milestoneIds).catch((error) => {
            this.displayExportDialog = true
        })
    }

    private isInProduction(milestone: FormattedMilestone): boolean {
        return milestone.latestExport !== undefined && milestone.latestExport > milestone.modificationDate
    }

    private get footer() {
        return tableFooter((key: string) => this.$t(key))
    }

    private mounted() {
        if (this.documents.length === 1) {
            this.selectedDocuments = this.documents
        }
    }

    private hideExportDialog() {
        this.$modules.milestones.loadMilestones()
        this.displayExportDialog = false
    }

}
