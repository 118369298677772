import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"mx-3 mt-3",attrs:{"justify":"space-between"}},[_c(VCol,{staticClass:"pa-0",attrs:{"tag":"h1"}},[_vm._v(_vm._s(_vm.$t('title.create-message')))]),_c(VCol,{staticClass:"pa-0 text-right"},[_c('IconButton',{attrs:{"leftIcon":"arrow_back","color":"secondary","to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('message.back-to-messages'))+" ")])],1)],1),_c(VRow,[_c(VFlex,{attrs:{"lg12":"","xl9":""}},[_c('Card',[_c('CardTitle',{attrs:{"icon":"edit"}},[_vm._v("2. "+_vm._s(_vm.$t('title.edit-form-draft')))]),_c('EditFormTitleStatic',{attrs:{"title":_vm.messageName}}),_c('MessageContentEdit',{attrs:{"document":_vm.document},on:{"contained":_vm.setIsContained}}),_c('EditFormNavigation',{attrs:{"save":"true","previous":"true","saveNext":"save","canSave":_vm.canSave,"next":"next","canNext":_vm.canNext && !_vm.canSave},on:{"previous":_vm.previous,"save":_vm.saveAndPush,"saveNext":_vm.saveNext,"next":_vm.next}}),_c('EditFormStepperMessage')],1)],1),_c(VFlex,{staticClass:"preview-flex mt-6 ml-xs-12 ml-sm-6 ml-md-6 ml-lg-6 ml-xl-0 mr-xl-0",attrs:{"sm12":"","md6":"","xl3":""}},[_c('EditFormPreview',{on:{"openPreview":function($event){_vm.dialogPreview = true}}})],1)],1),_c(VDialog,{attrs:{"max-width":"770","scrollable":""},model:{value:(_vm.dialogPreview),callback:function ($$v) {_vm.dialogPreview=$$v},expression:"dialogPreview"}},[_c(VCard,[_c(VCardActions,{staticClass:"headline"},[_c(VSpacer),_c('IconAction',{attrs:{"color":"secondary","icon":"highlight_off"},on:{"click":function($event){_vm.dialogPreview = false}}})],1),_c(VCardText,{staticClass:"text-center",staticStyle:{"height":"500px"}},[_c('EditFormPreview',{attrs:{"isZoomed":"","scale":"1.05"}})],1),_c(VDivider)],1)],1),_c('SaveCancelDialog',{ref:"saveCancelDialog",attrs:{"save":_vm.save,"canSave":_vm.canSave,"canCancel":_vm.canSave}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }