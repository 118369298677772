
















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

@Component
export default class EditFormDateRange extends Vue {

    @Prop(Array) private readonly value!: Array<string>
    @Prop(Array) private readonly notAllowedDates: Array<any> | undefined
    @Prop(Function) private updateRange!: () => void
    private eventDatesArray: Array<string> = []
    private menu = false

    private get dates(): Array<string> {
        return this.value
    }

    private set dates(dates: Array<string>) {
        this.$emit('input', dates)
    }

    private get datesRangeDisplay(): string | null {
        let fromDisplay = ''
        let toDisplay = ''

        this.dates.sort()

        if (this.dates[0] != null) {
            fromDisplay = new Date(this.dates[0]).toLocaleDateString()
        }
        if (this.dates[1] != null) {
            toDisplay = new Date(this.dates[1]).toLocaleDateString()
        }
        if (toDisplay === '') {
            if (fromDisplay === '') {
                return null
            }
            return fromDisplay
        }
        return fromDisplay + ' - ' + toDisplay
    }

    private allowedDate = (date: string) => {
        const time = Date.parse(date)
        if (undefined !== this.notAllowedDates) {
            for (const notAllowedRange of this.notAllowedDates) {
                if (time >= Date.parse(notAllowedRange.from) && time <= Date.parse(notAllowedRange.to)) {
                    return false
                }
            }
        }
        return true
    }

    @Watch('notAllowedDates')
    private eventDates() {
        this.eventDatesArray = []
        if (undefined !== this.notAllowedDates) {
            for (const notAllowedRange of this.notAllowedDates) {
                const date: Date = new Date(notAllowedRange.from)
                const to: Date = new Date(notAllowedRange.to)
                while (date.getTime() <= to.getTime()) {
                    this.eventDatesArray[this.eventDatesArray.length] =
                        date.toISOString().split('T')[0]
                    date.setDate(date.getDate() + 1)
                }
            }
        }
    }
}
