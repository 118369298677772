import {Document, Message, MessageState, MilestoneState} from '@/openapi'
import {Modules} from '@/store/modules'
import {
    CREATE_MESSAGE,
    DELETE_CAMPAIGN_FINISHED,
    DELETE_CAMPAIGN_NOT_STARTED,
    DELETE_MESSAGE_FINISHED,
    DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED,
    DELETE_MESSAGE_NOT_VALIDATED_RUNNING,
    DELETE_MESSAGE_VALIDATED_NOT_STARTED,
    DELETE_MESSAGE_VALIDATED_RUNNING,
    MANAGE_CAMPAIGN_NOT_STARTED,
    MANAGE_CAMPAIGN_RUNNING,
    MANAGE_EXPORTS,
    MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED,
    MODIFY_MESSAGE_NOT_VALIDATED_RUNNING,
    MODIFY_MESSAGE_VALIDATED_NOT_STARTED,
    MODIFY_MESSAGE_VALIDATED_RUNNING,
    VALIDATE_MESSAGE_NOT_STARTED,
    VALIDATE_MESSAGE_RUNNING,
} from '@/store/Right'
import {MessageDraft} from '@/store/types'

/**
 * Check if the logged user has the right to create a message for a given document.
 * @param modules Vuex modules containing data.
 * @param documentId Id of the document for which to create a message.
 */
export const userHasRightToCreateMessage = (modules: Modules, documentId: string): boolean => {
    // Retrieve the document
    const document: Document = modules.documents.getById(documentId)!
    // Check the creation right
    return modules.accounts.meHasRightOnDocument(document, CREATE_MESSAGE)
}

/**
 * Check if the logged user has the right to duplicate a message for a given document.
 * @param modules Vuex modules containing data.
 * @param messageId Id of the document for which to duplicate a message.
 */
export const userHasRightToDuplicateMessage = (modules: Modules, messageId: string): boolean => {
    let message: Message = modules.messages.getById(messageId)!
    if (message === undefined && modules.currentMessage.original !== undefined) {
        message = modules.currentMessage.original
    }
    const document: Document = modules.documents.getById(message.documentId)!
    // Check duplication right
    return modules.accounts.meHasDuplicateMessageRight(document)
}


/**
 * Check if the logged user has the right to edit a given message.
 * @param modules Vuex modules containing data.
 * @param messageId Id of the message to edit.
 */
export const userHasRightToEditMessage = (modules: Modules, messageId: string): boolean => {
    // Retrieve all the data (message, document, states).
    let message: Message = modules.messages.getById(messageId)!
    if (message === undefined && modules.currentMessage.current !== undefined) {
        message = modules.currentMessage.current
    }

    const document: Document = modules.documents.getById(message.documentId)!
    const messageState = message.state
    const milestoneState = message.milestoneId != null ?
        modules.milestones.milestoneStateById(message.milestoneId) : MilestoneState.NotStarted
    // Check the right depending on the state of the message and the milestone.
    if (messageState === MessageState.Validated) {
        return milestoneState === MilestoneState.NotStarted
            && modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_VALIDATED_NOT_STARTED)
            || milestoneState === MilestoneState.Running
            && modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_VALIDATED_RUNNING)
    } else {
        return milestoneState === MilestoneState.NotStarted
            && modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED)
            || milestoneState === MilestoneState.Running
            && modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_NOT_VALIDATED_RUNNING)
    }
}

/**
 * Check if the logged user has the right to delete a given message.
 * @param modules Vuex modules containing data.
 * @param messageId Id of the message to delete.
 */
export const userHasRightToDeleteMessage = (modules: Modules, messageId: string): boolean => {
    const message: Message = modules.messages.getById(messageId)!
    const document: Document = modules.documents.getById(message.documentId)!
    const milestoneState = message.milestoneId != null ?
        modules.milestones.milestoneStateById(message.milestoneId) : MilestoneState.NotStarted
    const messageIsValidated = message.state === MessageState.Validated
    switch (milestoneState) {
        case MilestoneState.NotStarted:
            if (messageIsValidated) {
                return modules.accounts.meHasRightOnDocument(document, DELETE_MESSAGE_VALIDATED_NOT_STARTED)
            } else {
                return modules.accounts.meHasRightOnDocument(document, DELETE_MESSAGE_NOT_VALIDATED_NOT_STARTED)
            }
        case MilestoneState.Running:
            if (messageIsValidated) {
                return modules.accounts.meHasRightOnDocument(document, DELETE_MESSAGE_VALIDATED_RUNNING)
            } else {
                return modules.accounts.meHasRightOnDocument(document, DELETE_MESSAGE_NOT_VALIDATED_RUNNING)
            }
        case MilestoneState.Finished:
            return modules.accounts.meHasRightOnDocument(document, DELETE_MESSAGE_FINISHED)
        default:
            return false
    }
}

/**
 * Check if the logged user has the right to validate a given message.
 * @param modules Vuex modules containing data.
 * @param message Message to validate.
 */
export const userHasRightToValidateMessage = (modules: Modules, message: MessageDraft): boolean => {
    // A message without document or milestone can not be validated.
    if (message.documentId == null || message.milestoneId == null) {
        return false
    }
    const document: Document = modules.documents.getById(message.documentId)!
    const milestoneState = modules.milestones.milestoneStateById(message.milestoneId)
    // Check the right depending on the milestone state.
    return milestoneState === MilestoneState.NotStarted
        && modules.accounts.meHasRightOnDocument(document, VALIDATE_MESSAGE_NOT_STARTED)
        || milestoneState === MilestoneState.Running
        && modules.accounts.meHasRightOnDocument(document, VALIDATE_MESSAGE_RUNNING)
}

/**
 * Check if the logged user has the right to edit a given id's user account.
 * @param modules Vuex modules containing data.
 * @param accountId Account id to edit.
 */
export const userHasRightToEditUser = (modules: Modules, accountId: string): boolean => {
    // user can edit its own account and others' if he has managerUsers right
    return (modules.accounts.meAccount !== undefined && modules.accounts.meAccount.id === accountId)
        || modules.accounts.meHasManageUsersRight()
}

/**
 * Check if the logged user has the right to manage exports for a given document.
 * @param modules Vuex modules containing data.
 * @param documentId Id of the document for which to manage exports.
 */
export const userHasRightToManageExports = (modules: Modules, documentId: string): boolean => {
    // Retrieve the document
    const document: Document = modules.documents.getById(documentId)!
    // Check the creation right
    return modules.accounts.meHasRightOnDocument(document, MANAGE_EXPORTS)
}

export const userHasRightToCreateMilestone = (modules: Modules): boolean => {
    return modules.accounts.meHasManageMilestoneRight()
}

export const userHasRightToEditMilestone = (modules: Modules, milestoneId: string): boolean => {
    // Retrieve the milestone
    const milestone = modules.milestones.getById(milestoneId)
    if (milestone === undefined) {
        return false
    }
    // Retrieve the milestone document
    const document = modules.documents.getById(milestone.documentId)
    if (document === undefined) {
        return false
    }
    // Retrieve the milestone state
    const milestoneState = milestone.state
    // Check the right
    switch (milestoneState) {
        case MilestoneState.Finished:
            return false
        case MilestoneState.NotStarted:
            return modules.accounts.meHasRightOnDocument(document, MANAGE_CAMPAIGN_NOT_STARTED)
        case MilestoneState.Running:
            return modules.accounts.meHasRightOnDocument(document, MANAGE_CAMPAIGN_RUNNING)
        default:
            return false
    }
}

export const userHasRightToDeleteMilestone = (modules: Modules, milestoneId: string): boolean => {
    // Retrieve the milestone
    const milestone = modules.milestones.getById(milestoneId)
    if (milestone === undefined) {
        return false
    }
    // Retrieve the milestone document
    const document = modules.documents.getById(milestone.documentId)
    if (document === undefined) {
        return false
    }
    // Retrieve the milestone state
    const milestoneState = milestone.state
    // Check the right
    switch (milestoneState) {
        case MilestoneState.Finished:
            return modules.accounts.meHasRightOnDocument(document, DELETE_CAMPAIGN_FINISHED)
        case MilestoneState.NotStarted:
            return modules.accounts.meHasRightOnDocument(document, DELETE_CAMPAIGN_NOT_STARTED)
        case MilestoneState.Running:
            return modules.accounts.meHasRightOnDocument(document, DELETE_CAMPAIGN_FINISHED)
        default:
            return false
    }
}
