import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
          transform: 'scale('+_vm.previewScale+')'
      }),attrs:{"id":"prev-container"},on:{"mouseover":function($event){_vm.hover=true},"mouseleave":function($event){_vm.hover=false}}},[_c('div',{style:({
          height: _vm.docPrevHeight + 'px',
          width: _vm.docPrevWidth + 'px',
          background: 'url('+ _vm.base64Image + ')'
      }),attrs:{"id":"document-preview"}},[_c('div',{staticClass:"quill-editor",style:({
              height: (_vm.txtPrevHeight + 2) + 'px', // add offset of 2 because of 1px solid border
              width: (_vm.txtPrevWidth + 2) + 'px', // add offset of 2 because of 1px solid border
              marginTop: _vm.txtMarginTop + 'px',
              marginLeft: _vm.txtMarginLeft + 'px'
          }),attrs:{"id":"text-preview"}},[_c('div',{ref:"prevEditorDiv",attrs:{"id":"prev-editor"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hover && !_vm.isZoomed),expression:"hover && !isZoomed"}],style:({
          height: _vm.docPrevHeight + 'px',
          width: _vm.docPrevWidth + 'px',
      }),attrs:{"id":"overlay"},on:{"click":function($event){return _vm.$emit('openPreview')}}},[_c(VIcon,{attrs:{"id":"overlay-item","size":_vm.docPrevWidth/3+'px'}},[_vm._v(" zoom_in ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }