import {Document, DocumentsApi, Image, ImagesApi} from '@/openapi/api'
import {AxiosResponse} from 'axios'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const documentsApi: DocumentsApi = new DocumentsApi(undefined, process.env.VUE_APP_API_URL, undefined)
const imagesApi: ImagesApi = new ImagesApi(undefined, process.env.VUE_APP_API_URL, undefined)


@Module
export default class DocumentsModule extends VuexModule {

    private documents: Map<string, Document> = new Map()
    private status: string = ''
    private currentDocumentImageObj: Image | undefined = undefined

    get all(): Array<Document> {
        return Array.from(this.documents.values())
    }

    get getById(): (id: string) => Document | undefined {
        return (id: string) => {
            if (this.documents.has(id)) {
                return this.documents.get(id)!
            } else {
                return undefined
            }
        }
    }

    @Mutation
    public reset() {
        this.documents = new Map()
        this.status = ''
    }

    @Mutation
    public request() {
        this.status = 'loading'
    }

    @Mutation
    public success() {
        this.status = 'success'
    }

    @Mutation
    public error() {
        this.status = 'error'
    }

    @Mutation
    public save(document: Document) {
        this.documents.set(document.id, document)
    }

    @Mutation
    public set(documents: Array<Document>) {
        this.documents = new Map(documents.map((doc) => [doc.id, doc]))
    }

    @Action
    public async loadDocuments() {
        this.request()
        return await documentsApi.listDocuments().then((response) => {
            this.set(response.data)
            this.success()
        }).catch((_: Error) => {
            this.error()
        })
    }

    @Action
    public async loadImage(documentId: string) {
        const document = this.documents.get(documentId)
        if (document !== undefined) {
            this.request()
            return await imagesApi.get(document.imageId).then((response: AxiosResponse<Image>) => {
                this.setCurrentDocumentImage(response.data)
                this.success()
            }).catch((_: Error) => {
                this.error()
            })
        }
    }


    @Mutation
    public setCurrentDocumentImage(image: Image) {
        this.currentDocumentImageObj = image
    }

    get currentDocumentImage(): Image | undefined {
        return this.currentDocumentImageObj
    }

    @Mutation
    public resetCurrentDocument() {
        this.currentDocumentImageObj = undefined
    }

}
