


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { MessageState } from '@/openapi/api'

@Component({})
export default class StateIndicator extends Vue {
    public static draft = '#FFA77D'
    public static validated = '#A4C752'
    public static exported = '#4E9AF1'

    public static COLOR_MAPPING: {[key: string]: string } = {
        [MessageState.Draft1]: StateIndicator.draft,
        [MessageState.Draft2]: StateIndicator.draft,
        [MessageState.Draft3]: StateIndicator.draft,
        [MessageState.Validated]: StateIndicator.validated, // light green
        exported: StateIndicator.exported,
    }

    public static LABEL_MAPPING: {[key: string]: string } = {
        [MessageState.Draft1]: 'message.states.draft',
        [MessageState.Draft2]: 'message.states.draft',
        [MessageState.Draft3]: 'message.states.draft',
        [MessageState.Validated]: 'message.states.validated',
        exported: 'message.states.exported',
    }

    @Prop(String) private readonly state!: string

    private get color(): string {
        return StateIndicator.COLOR_MAPPING[this.state] || 'grey'
    }

    private get label(): string {
        return this.$t(StateIndicator.LABEL_MAPPING[this.state]) as string || ''
    }
}
