

























































import {Criterion, Document, MilestoneState, Milestone, Segment, SegmentSelectionEnum} from '@/openapi/api'
import {MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED, MODIFY_MESSAGE_NOT_VALIDATED_RUNNING} from '@/store/Right'
import {MessageDraft} from '@/store/types'
import {flatMap} from 'lodash'
import {Component, Vue} from 'vue-property-decorator'

@Component
export default class MessageConditions extends Vue {

    // Threshold of segment criteria number which defines the transition from half- to full-page for criteria display
    private splitCriteriaTh = 10
    private campaignAlreadyHasDefaultMessage: boolean = false

    //
    // CURRENT
    //

    private get current(): MessageDraft {
        return this.$modules.currentMessage.draftAndCurrent
    }

    private get isDefaultMessage(): boolean | undefined {
        return this.current.isDefault
    }

    private set isDefaultMessage(isDefault: boolean | undefined) {
        this.current.isDefault = isDefault
    }

    //
    // DOCUMENT
    //

    private get draftDocumentId(): string {
        const documentId = this.current.documentId
        if (documentId === undefined) {
            throw Error('In step 3 (conditions), the `currentMessage.documentId` must be defined.')
        }
        return documentId
    }


    //
    // MILESTONE
    //

    private get milestones(): Array<Milestone> {
        const document: Document = this.$modules.documents.getById(this.draftDocumentId)!
        return this.$modules.milestones.milestonesWithDocumentId(this.draftDocumentId)
            .filter((milestone) => {
                switch (milestone.state) {
                    case MilestoneState.NotStarted:
                        return this.hasRightToModifyMessageNotValidatedNotStarted(document)
                    case MilestoneState.Running:
                        return this.hasRightToModifyMessageNotValidatedRunning(document)
                    case MilestoneState.Finished:
                        return false
                }
            })
    }

    private get hasRightToModifyMessageNotValidatedNotStarted(): (document: Document) => boolean {
        return (document: Document) => {
            return this.$modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_NOT_VALIDATED_NOT_STARTED)
        }
    }

    private get hasRightToModifyMessageNotValidatedRunning(): (document: Document) => boolean {
        return (document: Document) => {
            return this.$modules.accounts.meHasRightOnDocument(document, MODIFY_MESSAGE_NOT_VALIDATED_RUNNING)
        }
    }

    private get draftMilestone(): Milestone | undefined {
        const milestoneId = this.current.milestoneId
        if (milestoneId !== undefined) {
            return this.$modules.milestones.getById(milestoneId)
        }
        return undefined
    }

    private set draftMilestone(milestone: Milestone | undefined) {
        if (milestone !== undefined) {
            this.$modules.currentMessage.setDraftMilestoneId(milestone.id)
        }
    }


    //
    // CRITERIA
    //

    private get segments(): Array<Segment> {
        return this.$modules.segments.segmentsWithDocumentId(this.draftDocumentId).sort((a, b) => {
            return a.order - b.order
        })
    }

    private get draftCriteria(): Array<Array<Criterion>> {
        const criteriaIds = this.current.criteriaIds || []
        return this.segments.map((segment) =>
            segment.criteria.filter((criterion) => criteriaIds.includes(criterion.id)),
        )
    }

    private selectCriteria(index: number, criteria: Array<Criterion>) {
        const selected = flatMap(this.draftCriteria, (c, i) => (i === index) ? criteria : c)
        this.$modules.currentMessage.setDraftCriteria(selected)
    }

    private selectDefault(selected: boolean) {
        this.$modules.currentMessage.setIsDefault(selected)
    }

    private segmentTypeIsSingle(segmentType: SegmentSelectionEnum) {
        return segmentType === SegmentSelectionEnum.SingleValue
            || segmentType === SegmentSelectionEnum.SingleValueMandatory
    }

    private milestoneLongName(milestone: Milestone): string {
        const name = (milestone.name.length > 32) ? milestone.name.substring(0, 32) + '...' : milestone.name
        return name + ' (' + this.fmtDate(milestone.dateStart) + ' - ' + this.fmtDate(milestone.dateEnd) + ')'
    }

    private fmtDate(date: string): string {
        return date.substring(8, 10) + '/' + date.substring(5, 7) + '/' + date.substring(0, 4)
    }
}
