var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentId === undefined)?_c('EditFormStepper',{attrs:{"steps":[
    '/create-message',
    '/create-message',
    '/create-message'
],"stepPtr":_vm.currentState}}):_c('EditFormStepper',{attrs:{"steps":[
    '/create-message/'+_vm.currentId+'/step-1-type',
    '/create-message/'+_vm.currentId+'/step-2-draft',
    '/create-message/'+_vm.currentId+'/step-3-conditions'
],"stepPtr":_vm.currentState}})}
var staticRenderFns = []

export { render, staticRenderFns }