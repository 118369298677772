




























































































































































































































































































































import {CREATE_MESSAGE, MANAGE_EXPORTS} from '@/store/Right'
import {Component, Vue, Watch} from 'vue-property-decorator'

@Component
export default class AppTemplate extends Vue {

    /**
     * Watcher on router which verifies that the user is well-authenticated before accessing the app routes.
     * @param newUrl Route to be accessed
     */
    @Watch('$route', {immediate: true, deep: true})
    private checkAuthentication(newUrl: string) {
        if (this.$modules.accounts.token === undefined) {
            this.$router.push('/login')
        }
    }

    private get version(): string {
        switch (process.env.NODE_ENV) {
            case 'development':
                return 'v' + (process.env.VUE_APP_VERSION) + '-dev'
            case 'test':
                return 'v' + (process.env.VUE_APP_VERSION) + '-test'
            case 'production':
                return 'v' + (process.env.VUE_APP_VERSION)
            default:
                return ''
        }
    }

    private get canCreateMessage() {
        return this.$modules.accounts.meHasRightOnOneDocument(CREATE_MESSAGE)
    }

    private get canManageExports() {
        return this.$modules.accounts.meHasRightOnOneDocument(MANAGE_EXPORTS)
    }

    private logout() {
        this.$router.push('/login')
    }

    private get meName() {
        return this.$modules.accounts.meName
    }

    private canManageUsers() {
        return this.$modules.accounts.meHasManageUsersRight()
    }
}
