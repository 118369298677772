

































































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class CreatePassword extends Vue {

    @Prop(String) private readonly token!: string
    private unactivated: boolean = true

    private mounted() {
        this.unactivated = this.$modules.accounts.currentActivationError !== 401
            && this.$modules.accounts.currentActivationAccount!.firstLogin
    }
}
