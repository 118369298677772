import {Message, Segment, SegmentsApi} from '@/openapi/api'
import {AxiosResponse} from 'axios'
import {Action, Module, Mutation, VuexModule} from 'vuex-class-modules'

const segmentsApi: SegmentsApi = new SegmentsApi(undefined, process.env.VUE_APP_API_URL, undefined)

@Module
export default class SegmentsModule extends VuexModule {

    private segments: { [key: string]: Segment } = {}

    get all(): Array<Segment> {
        return Object.values(this.segments)
    }

    get getById(): ((id: string) => Segment) {
        return (id: string) => this.segments[id]
    }

    get segmentsWithDocumentId(): ((documentId: string) => Array<Segment>) {
        return (documentId: string) => {
            return this.all.filter((segment) => segment.documentId === documentId)
        }
    }

    @Mutation
    public reset() {
        this.segments = {}
    }

    @Mutation
    public setSegments(segments: Array<Segment>) {
        this.segments = {}
        segments.forEach((seg) => {
            this.segments[seg.id] = seg
        })
    }

    @Action
    public async loadSegments() {
        await segmentsApi.listSegments().then((response: AxiosResponse<Array<Segment>>) => {
            this.setSegments(response.data)
        })
    }

}
