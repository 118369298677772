import Next from '@/router/Next'
import {fetchData} from '@/store/actions/globalActions'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export default (to: Route, from: Route, next: Next) => {
    return fetchData(modules).then(() => {
        next()
    }).catch((_) => {
        next('/login')
    })
}

