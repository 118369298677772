import Next from '@/router/Next'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export function loadMilestones(to: Route, from: Route, next: Next) {
    modules.milestones.loadMilestones().then(() => {
        next()
    })
}

