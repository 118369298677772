























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class IconAction extends Vue {

    @Prop(String) private readonly icon!: string

}
