import Next from '@/router/Next'
import {userHasRightToDuplicateMessage} from '@/store/actions/rightActions'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export default function checkRightEditMessage(to: Route, from: Route, next: Next) {
    const messageId = to.params.id
    if (userHasRightToDuplicateMessage(modules, messageId)) {
        next()
    } else {
        // Redirect to all messages page if needed.
        next('/messages')
    }

}
