






























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class IconButton extends Vue {

    @Prop(String) private readonly leftIcon!: string
    @Prop(String) private readonly rightIcon!: string
    @Prop({default: false}) private readonly small!: boolean
    @Prop({default: false}) private readonly text!: boolean

}
