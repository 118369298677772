import AccountsModule from '@/store/modules/AccountsModule'
import CurrentMessageModule from '@/store/modules/CurrentMessageModule'
import DocumentsModule from '@/store/modules/DocumentsModule'
import MessagesModule from '@/store/modules/MessagesModule'
import MilestonesModule from '@/store/modules/MilestonesModule'
import SegmentsModule from '@/store/modules/SegmentsModule'
import FontsModule from '@/store/modules/FontsModule'
import store from '@/store/store'

const accounts = new AccountsModule({
    store,
    name: 'accounts',
})

const messages = new MessagesModule({
    store,
    name: 'messages',
})

const documents = new DocumentsModule({
    store,
    name: 'documents',
})

const milestones = new MilestonesModule({
    store,
    name: 'milestones',
})

const segments = new SegmentsModule({
    store,
    name: 'segments',
})

const currentMessage = new CurrentMessageModule({
    store,
    name: 'currentMessage',
    milestonesModule: milestones,
    segmentsModule: segments,
})

const fonts = new FontsModule({
    store,
    name: 'fonts',
})

const modules = {
    accounts,
    messages,
    currentMessage,
    documents,
    milestones,
    segments,
    fonts,
}

export type Modules = typeof modules
export default modules
