import Next from '@/router/Next'
import modules from '@/store/modules'
import {Route} from 'vue-router'

export function loadAccounts(to: Route, from: Route, next: Next) {
    modules.accounts.loadAccounts().then(() => {
        next()
    })
}


export function loadMe(to: Route, from: Route, next: Next) {
    modules.accounts.loadMe().then(() => {
        next()
    })
}
