import {ExportApi} from '@/openapi'
import {Modules} from '@/store/modules'
import {AxiosResponse} from 'axios'
import {saveAs} from 'file-saver'

const exportApi: ExportApi = new ExportApi(undefined, process.env.VUE_APP_API_URL, undefined)

export const exportCampaigns = async (modules: Modules, milestonesId: Array<string>) => {
    await exportApi.zipExport(milestonesId, {responseType: 'blob'}).then((response: AxiosResponse<Blob>) => {
        const blob = new Blob([response.data], {type: 'application/zip'})
        saveAs(blob, 'export.zip')
    }).then(() => {
        for (const milestoneId of milestonesId) {
            modules.milestones.updateExportDate(milestoneId)
        }
    })
}
