























import Vue from 'vue'
import {Component, Prop} from 'vue-property-decorator'

@Component
export default class IconTooltip extends Vue {
    @Prop(String) private readonly icon!: string
    @Prop(String) private readonly text!: string
}
