import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c(VCard,{staticClass:"mx-auto padded",attrs:{"max-width":"600"}},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"white--text",attrs:{"height":"200px","src":require("@/ui/assets/logo-KAMI-outside-RVB-WEB-transparent.png"),"alt":"Logo Kami Outside"}})]),(!_vm.emailSent)?_c('div',[_c(VCardText,[_c(VLayout,[_c(VFlex,[_c('div',{staticClass:"text--primary text-center"},[_vm._v(_vm._s(_vm.$t('forgotten-password.message1')))]),_c('div',{staticClass:"text--primary text-center"},[_vm._v(_vm._s(_vm.$t('forgotten-password.message2')))]),_c('br'),_c(VTextField,{attrs:{"label":_vm.$t('forgotten-password.email')},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleValidate.apply(null, arguments)}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1),_c(VCardActions,{staticClass:"justify-center"},[_c('IconButton',{attrs:{"color":"success","leftIcon":"replay"},on:{"click":_vm.handleValidate}},[_vm._v(" "+_vm._s(_vm.$t('forgotten-password.validate'))+" ")])],1)],1):_c(VCardText,[_c('div',{staticClass:"text--primary text-center"},[_vm._v(_vm._s(_vm.$t('forgotten-password.sent-message')))])]),(_vm.emailNotFound)?_c(VCardText,[_c('p',{staticClass:"error-frame text-center v-size--small"},[_vm._v(" "+_vm._s(_vm.$t('forgotten-password.email-not-found'))+" ")])]):_vm._e(),(_vm.accountNotActivated)?_c(VCardText,[_c('p',{staticClass:"error-frame text-center v-size--small"},[_vm._v(" "+_vm._s(_vm.$t('forgotten-password.account-not-activated'))+" ")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }