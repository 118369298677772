import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"mx-3 mt-3",attrs:{"justify":"space-between"}},[_c(VFlex,[_c('h1',[_vm._v(_vm._s(_vm.$t('all-roles.title')))])])],1),_c(VRow,[_c(VFlex,{attrs:{"xs12":"","md6":""}},[_c('Card',{attrs:{"fluid":"","id":"roles-table"}},[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.sections,"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return _vm._l((items),function(section){return _c('tbody',{staticClass:"section"},[_c('tr',[_c('td',{staticClass:"text-left",attrs:{"colspan":section.colspan}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(section.display))])]),(section.primary !== undefined)?_vm._l((_vm.roles),function(role){return _c('td',{staticClass:"text-center"},[(section.primary !== undefined && _vm.roleHasRight(role, section.primary))?[_c(VIcon,[_vm._v(" done ")]),(section.secondary !== undefined && !_vm.roleHasRight(role, section.secondary))?[_vm._v(" * ")]:_vm._e()]:_vm._e()],2)}):_vm._e()],2),_vm._l((section.rights),function(right){return _c('tr',[_c('td',{staticClass:"text-left indent"},[_vm._v(_vm._s(right.display))]),_vm._l((_vm.roles),function(role){return _c('td',{staticClass:"text-center"},[(_vm.roleHasRight(role, right.primary))?[_c(VIcon,[_vm._v(" done ")]),(right.secondary !== undefined && !_vm.roleHasRight(role, right.secondary))?[_vm._v(" * ")]:_vm._e()]:_vm._e()],2)})],2)})],2)})}}])}),_c(VCardText,{staticClass:"pa-0 flex-container"},[_c(VRow,[_c(VCol,{staticStyle:{"font-size":"9pt"}},[_vm._v(_vm._s(_vm.$t('all-roles.special-note')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }