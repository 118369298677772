












import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class EditFormTitleStatic extends Vue {
    @Prop(String) private readonly title!: string
}
