import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","max-width":800},model:{value:(_vm.display),callback:function ($$v) {_vm.display=$$v},expression:"display"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline error",domProps:{"innerHTML":_vm._s(_vm.$t('milestone.default_message.title'))}}),_c('div',{staticClass:"popupContent"},[_c(VRow,[_c(VCol,{attrs:{"cols":"1"}},[_c(VCardText,[_c(VIcon,{attrs:{"large":"","color":"error"}},[_vm._v("error")])],1)],1),_c(VCol,[_c(VCardText,[_c('span',{staticClass:"firstSentence"},[_vm._v(_vm._s(_vm.$t('milestone.default_message.subTitle1')))]),_c('br'),_c('p',[_vm._v(_vm._s(_vm.$t('milestone.default_message.subTitle2')))])])],1)],1),_c(VCardActions,{staticClass:"buttonLine"},[_c(VSpacer),_c('IconButton',{staticClass:"primary",attrs:{"leftIcon":"thumb_up"},on:{"click":_vm.confirmAction}},[_vm._v(" "+_vm._s(_vm.$t('milestone.default_message.confirmButton'))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }